// Method To Store User Session
export function setUserSession(data){
    return localStorage.setItem('userOnboardData', JSON.stringify(data));
}
// Method To Fetch User Session
export function getUserSession(){
    return localStorage.getItem('userOnboardData');
}
// Method To Remove User Session
export function removeUserSession(){
    return localStorage.removeItem('userOnboardData');
}

// Method To Store SelectedToken
export function setSelectedToken(data){
  return localStorage.setItem('selectedToken', JSON.stringify(data));
}
// Method To Fetch SelectedToken
export function getSelectedToken(){
  return localStorage.getItem('selectedToken');
}
// Method To Remove SelectedToken
export function removeSelectedToken(){
  return localStorage.removeItem('selectedToken');
}

export function storeCurrencies(data){
  return localStorage.setItem('currencies', JSON.stringify(data));
}

export function getCurrencies(){
  return JSON.parse(localStorage.getItem('currencies') || "[]");
}