import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

// import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

export default function Auth() {
  return (
    <>
      {/* <Navbar transparent /> */}
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full  bg-no-repeat bg-full"
            style={{
              backgroundColor:'white'
            }}
          ></div>
          <Switch>
            <Route path="/login/:id/:test" exact component={Login} />
            <Route path="/login" exact component={Login} />
            <Route path="/login/employer-registration" exact component={Register} />
            <Redirect from="/" to="/login" />
            <Redirect from="/login" to="/login/:id/:test" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
