import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "components/spinner/spinner";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { useHistory } from "react-router-dom";
import {
  getUserSession,
  removeUserSession,
  setUserSession,
  setSelectedToken,
  getSelectedToken,
} from "../config/localstorage";
// components
import { db } from "../config/firebase";
import { CryptoFundamentales_TestTimeLimit, Solidity101s_TestTimeLimit, SolidityIntermediate_TestTimeLimit, BlockchainIntermediate_TestTimeLimit, DeploymentLink } from "config/config";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall";

export default function Landing() {
  const [subject, setSubject] = useState("");
  const [level, setLevel] = useState("");
  const [idd, setID] = useState("");
  const [tests, setTest] = useState("");
  const [block, setBlock] = useState(false);
  const [email, setEmail] = useState("");
  const [iddd, setId] = useState("");
  const [num, setNum] = useState(false);
  const [docid, setDocid] = useState("");

  let { id, test } = useParams();
  console.log(test, "test");
  useEffect(() => {
    if (
      id === undefined ||
      id === null ||
      test === undefined ||
      test === null
    ) {
      console.log(0);
      setID(0);
      setTest("");
    } else {
      console.log(id, test);
      setID(id);
      setTest(test);
    }
  });

  console.log(tests, "test");

  useEffect(() => {
    const tempData = getSelectedToken();
    console.log(tempData);
    if (tempData) {
      const walletData = JSON.parse(tempData);
      console.log(walletData);
      console.log(walletData.email);
      setEmail(walletData.email);
      console.log(email);
      console.log(typeof email);
      getting(walletData.email);
    }
  });

  async function getting(email) {
    console.log("call");
    console.log(email);

    try {
      const docRef = doc(db, "users", email);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        console.log(docSnap.data().employerId);
        console.log(docSnap.data().employerId, "emailemployer");
        const id = docSnap.data().employerId;
        const docReff = doc(db, "employers", id);
        const docSnapp = await getDoc(docReff);
        if (docSnapp.exists()) {
          console.log(docSnapp.data());
          console.log(docSnapp.data().email, "employer");
          setId(docSnapp.data().email);
        }
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    timer();
    console.log(iddd, "Email employer");
  }, [iddd]);

  async function get() {
    console.log(iddd, "get");
  }

  async function timer() {
    try {
      console.log(iddd, "timer", test, Timestamp.now());
      const time = Timestamp.now();

      const q = query(
        collection(db, "assessments"),
        where("employerEmail", "==", iddd),
        where("email", "==", email),
        where("test", "==", test)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        console.log(doc.id, " => ", doc.data().endTime);
        if (doc.data().endTime <= time) {
          setBlock(true);
          console.log("time up");
          setNum(true);
        } else if (doc.data().status == "Complete") {
          setBlock(true);
          console.log(doc.data().status, "Status from firebase");
        } else {
          setDocid(doc.id);
          setBlock(false);
          console.log("time not up");
          setNum(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function enter(tests) {
    let min = 0;
    if (tests == "CryptoFundamentals") {
      min = CryptoFundamentales_TestTimeLimit;
    } else if (tests == "SolidityIntermediate") {
      min = SolidityIntermediate_TestTimeLimit;
    } else if (tests == "BlockchainIntermediate") {
      min = BlockchainIntermediate_TestTimeLimit;
    } else {
      min = Solidity101s_TestTimeLimit
    }
    if (!docid) {
      console.log(iddd, "employer email");
      console.log(email);
      try {
        const oldDateObj = new Date();
        const newDateObj = new Date();

        console.log(min)
        newDateObj.setTime(oldDateObj.getTime() + min * 60 * 1000);
        const docRef = await addDoc(collection(db, "assessments"), {
          email: email,
          employerEmail: iddd,
          created: Timestamp.now(),
          endTime: newDateObj,
          test: tests,
          status: "Expired",
        });
        console.log("Document written with ID: ", docRef.id);
        // setLink(`localhost:3000/?${docRef.id}`)
        window.location.href = `${DeploymentLink}/${tests}`;
        // window.location.href = `http://localhost:3000/${test}`
      } catch (err) {
        alert(err);
      }
    } else {
      window.location.href = `${DeploymentLink}/${tests}`;
      // window.location.href = `http://localhost:3000/${test}`
    }
  }
  async function submit() {
    // console.log(subject);
    // console.log(level);
    console.log(iddd);
    // window.location.href = 'CryptoFundamentals'
    if (test == "CryptoFundamentals") {
      console.log("call");
      const t = "CryptoFundamentals";
      enter(t);
      // setLink(`localhost:3000/?${docRef.id}`)
      // window.location.href = 'http://localhost:3000/CryptoFundamentals'
    } else if (test == "SolidityIntermediate") {
      const t = "SolidityIntermediate";
      enter(t);
    } else if (test == "BlockchainIntermediate") {
      const t = "BlockchainIntermediate";
      enter(t);
    } else {
      const t = "Solidity101";
      enter(t);
      // setLink(`localhost:3000/?${docRef.id}`)
      // window.location.href = 'http://localhost:3000/Solidity101'
    }
  }
  return (
    <>
      <Navbar transparent />
      <main style={{ backgroundColor: "white" }}>
        <section className="pb-20 relative block ">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-black">
                  {test == "JRdzVgCG" ? "Crypto Fundamentals" : null}
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section
          className="relative block py-24 lg:pt-0 "
          style={{ backgroundColor: "white" }}
        >
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div
                  className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg  rounded-lg bg-blueGray-200"
                  style={{ marginBottom: "50%" }}
                >
                  <img
                    src={require("./company-extended.png").default}
                    alt="logo"
                    style={{ width: "200px", height: "10%", marginLeft: "5%", marginTop: "5%", marginBottom: "1%" }} />
                  <div className="flex-auto p-0 lg:p-0 mt-0">
                    <h4
                      className="text-3xl font-semibold font-Onest"
                      style={{ color: "black", paddingTop: "0%", marginLeft: "5%", marginTop: "2%" }}
                    >
                      {" "}
                      {test == "CryptoFundamentals"
                        ? " Crypto Fundamentals " :
                        test == "SolidityIntermediate" ?
                          "Solidity Intermediate" :
                          test == "BlockchainIntermediate" ?
                            "Blockchain Intermediate"
                            : "Solidity 101"}{" "}
                      Assessment
                    </h4>
                    <hr className="mt-6 border-b-1 border-blueGray-300" />

                    <p class="leading-relaxed mt-4 mb-4 text-blueGray-500 font-Onest font-bold text-1xl" style={{ color: "black", marginLeft: "5%", marginTop: "10%" }}>Begin your assessment here.</p>
                    <p class="leading-relaxed mt-4 mb-4 text-blueGray-500 font-Onest font-Onest" style={{ marginLeft: "5%", paddingRight: "5%" }}>
                      {" "}{test == "CryptoFundamentals" ? "Our Crypto Fundamentals assessment tests a candidate’s fundamental knowledge of cryptocurrency, blockchain technology, and the broader Web3 landscape." :
                      test == "SolidityIntermediate" ? "Our Solidity Intermediate test is designed to assess the technical skills of advanced candidates with a strong foundation in Solidity and smart contract structure and deployment and debugging on a blockchain." :
                      test == "BlockchainIntermediate" ? "Our Blockchain Fundamentals assessment tests blockchain concepts and ensures that all technical team candidates have a strong foundation in the technology." :
                      "Our Solidity 101 test assesses a candidate's basic technical proficiency in Solidity, smart contract structure, deployment, and debugging on a blockchain."}{""}
                      <br /><br />This is a timed assessment. Click on the <strong style={{ color: "black" }}>Start Assessment</strong> button when you are ready. 
                    </p>

                    <div className="inline-block" style={{ marginLeft: "5%"}}>
                      <h2 className="font-Onest">
                        Test Duration: <strong>{" "}{test == "CryptoFundamentals"
                          ? CryptoFundamentales_TestTimeLimit :
                          test == "SolidityIntermediate" ?
                            SolidityIntermediate_TestTimeLimit :
                            test == "BlockchainIntermediate" ?
                              BlockchainIntermediate_TestTimeLimit
                              : Solidity101s_TestTimeLimit}{""} minutes</strong>
                      </h2>
                    </div>

                    {!block ? (

                      <div class="text-center mt-1" style={{ display: "inline-block" }}><button class=" text-white active:bg-lightBlue-800 bg-lightBlue-600 w-full text-sm font-Onest px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150" type="button" style={{ marginLeft: "80%", marginTop: "5%", display: "inline" }} onClick={submit}>Start Assessment</button></div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <h1 style={{ color: "red", fontSize: "20px", fontFamily: "Onest" }} className="font-Onest">
                          <i>This link has expired</i>
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <FooterSmall absolute /> */}
    </>
  );
}
