import React, { useState, useEffect } from "react";
import { auth, db } from "../../config/firebase";
import {
  collection,
  addDoc,
  doc,
  Timestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { DeploymentLink, username, password } from "config/config";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [test, setTest] = useState("CryptoFundamentals");
  const [link, setLink] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(false);

  async function register() {
    try {
      console.log("call");

      const Crflag = false;
      const SOLflag = false;

      // if (test == "CryptoFundamentals") {
      //   Crflag = true;
      // } else {
      //   SOLflag = true;
      // }

      console.log(email);
      const q = query(collection(db, "employers"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0) {
        const documentSnapshot = querySnapshot.docs[0];
        console.log(documentSnapshot.data());
        console.log(documentSnapshot.id);
        if (test == "CryptoFundamentals") {
          const docRef = await updateDoc(doc(db, "employers", documentSnapshot.id), {
            CryptoFundamentals: true,
          })
        } else if (test == "SolidityIntermediate") {
          const docRef = await updateDoc(doc(db, "employers", documentSnapshot.id), {
            SolidityIntermediate: true,
          })
        } else if (test == "BlockchainIntermediate") {
          const docRef = await updateDoc(doc(db, "employers", documentSnapshot.id), {
            BlockchainIntermediate: true,
          })
        } else {
          const docRef = await updateDoc(doc(db, "employers", documentSnapshot.id), {
            Solidity101: true,
          })
        }
        setLink(`${DeploymentLink}/login/${documentSnapshot.id}/${test}`);
      } else {
        console.log("not availble");
        console.log(name, email);
        if (test == "CryptoFundamentals") {
          const docRef = await addDoc(collection(db, "employers"), {
            email: email,
            name: name,
            createdTime: Timestamp.now(),
            CryptoFundamentals: true,
            Solidity101: false,
            SolidityIntermediate: false,
            BlockchainIntermediate: false
          });
          console.log("Document written with ID: ", docRef.id);
          console.log(test);
          setLink(`${DeploymentLink}/login/${docRef.id}/${test}`);
        } else if (test == "SolidityIntermediate") {
          const docRef = await addDoc(collection(db, "employers"), {
            email: email,
            name: name,
            createdTime: Timestamp.now(),
            CryptoFundamentals: false,
            Solidity101: false,
            SolidityIntermediate: true,
            BlockchainIntermediate: false
          });
          console.log("Document written with ID: ", docRef.id);
          console.log(test);
          setLink(`${DeploymentLink}/login/${docRef.id}/${test}`);
        } else if (test == "BlockchainIntermediate") {
          const docRef = await addDoc(collection(db, "employers"), {
            email: email,
            name: name,
            createdTime: Timestamp.now(),
            CryptoFundamentals: false,
            Solidity101: false,
            SolidityIntermediate: false,
            BlockchainIntermediate: true
          });
          console.log("Document written with ID: ", docRef.id);
          console.log(test);
          setLink(`${DeploymentLink}/login/${docRef.id}/${test}`);
        } else {
          const docRef = await addDoc(collection(db, "employers"), {
            email: email,
            name: name,
            createdTime: Timestamp.now(),
            CryptoFundamentals: false,
            Solidity101: true,
            SolidityIntermediate: false,
            BlockchainIntermediate: false
          });
          console.log("Document written with ID: ", docRef.id);
          console.log(test);
          setLink(`${DeploymentLink}/login/${docRef.id}/${test}`);
        }
      }

    } catch (err) {
      alert(err);
    }
  }

  async function login() {
    if (username == `${username}` && password == `${password}`) {
      setStatus(true);
    } else {
      alert("Login Failed!!");
    }
  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="mb-3">
                  <img
                    src={require("../../assets/img/company-extended.png").default}
                    alt="logo"
                    style={{ width:"151px", height:"10%", marginLeft: "3%" }} />
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-2">
                {!status ? (
                  <form>
                    <div className="relative w-full mb-3 mt-2 pt-5">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-Onest mb-2"
                        htmlFor="grid-password"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3 mt-2 pt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-Onest mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="text-center mt-12">
                      <button
                        className="bg-lightBlue-600 text-white active:bg-lightBlue-800 text-sm font-Onest px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={login}
                      >
                        Log in
                      </button>
                    </div>
                  </form>
                ) : (
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-Onest mb-2 mt-5"
                        htmlFor="grid-password"
                      >
                        Employer Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onChange={(e) => { 
                          setName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-Onest mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-Onest mb-2"
                        htmlFor="grid-password"
                      >
                        Test
                      </label>
                      <select onChange={(e) => setTest(e.target.value)}>
                        <option value="CryptoFundamentals" className="font-Onest">
                          Crypto Fundamentals
                        </option>
                        <option value="Solidity101" className="font-Onest">Solidity 101</option>
                        <option value="SolidityIntermediate" className="font-Onest">Solidity Intermediate</option>
                        <option value="BlockchainIntermediate" className="font-Onest">Blockchain Intermediate</option>
                      </select>
                    </div>

                    <div className="mt-10">
                      <button
                        className="bg-lightBlue-600 text-white active:bg-lightBlue-800 text-sm font-Onest px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={register}
                      >
                        Create Account
                      </button>
                      {link}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
