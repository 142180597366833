import React, { useEffect, useState } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall";
import useWindowDimensions from "./windowDimension";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import "./test.css";
import LoadingSpinner from "components/spinner/spinner";
import {
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  Timestamp,
  where,
  query,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import {
  getUserSession,
  removeUserSession,
  setUserSession,
  setSelectedToken,
  getSelectedToken,
} from "../../config/localstorage";
import "../timer/App.css";
import { useCountdown } from "../timer/hooks/useCountdown";
// import CountdownTimer from '../timer/CountdownTimer.js';

import DateTimeDisplay from "../timer/DateTimeDisplay";
// import { useCountdown } from '../timer/hooks/useCountdown';
export default function Test1() {
  const { height, width } = useWindowDimensions();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [time, setTime] = useState();
  const [id, setId] = useState("");
  const [link, setLink] = useState("");
  const [min, setMin] = useState();
  const [block, setBlock] = useState();
  const [docid, setDoc] = useState("");
  const history = useHistory();
  console.log(min + 1, "min");
  const THREE_DAYS_IN_MS = min * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  //leaving popup

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  // useEffect(() => {
  //   window.history.go(1);
  // }, []);

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   const message =
  //     "Are you sure you want to leave? All provided data will be lost.";
  //   e.returnValue = message;
  //   return message;
  // };

  useEffect(() => {
    const tempData = getSelectedToken();
    console.log(tempData);
    if (tempData) {
      const walletData = JSON.parse(tempData);
      console.log(walletData);
      console.log(walletData.email);
      setEmail(walletData.email);
      console.log(email);
      console.log(typeof email);
      get(walletData.email);
    }
  }, []);
  useEffect(() => {
    status();
  }, [id, docid]);
  async function get(email) {
    console.log("call");
    console.log(email);
    setLoading(true);
    try {
      const docRef = doc(db, "users", email);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        console.log(docSnap.data().employerId);
        console.log(docSnap.data().employerId, "id");
        const id = docSnap.data().employerId;
        const docReff = doc(db, "employers", id);
        const docSnapp = await getDoc(docReff);
        if (docSnapp.exists()) {
          console.log(docSnapp.data());
          console.log(docSnapp.data().email);
          setId(docSnapp.data().email);
          console.log(docSnapp.id, "test ID");
          setDoc(docSnapp.id);
          // setBlock(true);
        }
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function status() {
    console.log("call");
    // const time = Timestamp.now();

    console.log(id, "get");
    const q = query(
      collection(db, "assessments"),
      where("employerEmail", "==", id),
      where("email", "==", email),
      where("test", "==", "CryptoFundamentals")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.data(), "data from firebbase");

      if (doc.data().endTime <= time) {
        setBlock(true);
        console.log("time up");
      } else {
        setTime(doc.data().endTime); //end time
        console.log(doc.data().endTime.seconds, "seconds end time");
        const Ftime = doc.data().endTime.seconds;
        console.log(doc.id, "TESTid");
        const current = Timestamp.now();
        console.log(current.seconds, "seconds current time");
        const Ctime = current.seconds;
        const tim = Ftime - Ctime;
        console.log(Math.floor(tim / 60), "minutes");
        const minn = Math.floor(tim / 60);
        setMin(minn + 1);
        setBlock(false);
        setLoading(false);
        console.log("time not up");
      }
    });
  }

  return (
    <>
      <Navbar />

      {!block ? (
        <CountdownTimer
          targetDate={dateTimeAfterThreeDays}
          email={email}
          id={id}
        />
      ) : (
        <>
          {" "}
          <p>You failed to submit your test within the required time</p>
        </>
      )}
    </>
  );
}

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Your time is over!</span>
      <p>You failed to submit your test within the required time</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter" style={{ position: "fixed", top: 0 }}>
      <a
        href="https://tapasadhikary.com"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link"
      >
        <DateTimeDisplay
          value={minutes}
          type={"Mins"}
          isDanger={minutes <= 2}
        />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"Seconds"} />
      </a>
    </div>
  );
};

const CountdownTimer = ({ targetDate, email, id }) => {
  const { height, width } = useWindowDimensions();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [docid, setDocID] = useState("");
  const [link, setLink] = useState("");
  // console.log(email, id, "email in countdown");
  // console.log(docid, "docid");
  if (minutes <= 1 && seconds >= 59) {
    toast.error(
      "You are running out of time! Please submit your assessment before the timer ends.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }

  useEffect(async () => {
    const q = query(
      collection(db, "assessments"),
      where("employerEmail", "==", id),
      where("email", "==", email),
      where("test", "==", "CryptoFundamentals")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, "data from firebbase in a component");
      console.log(typeof doc.id);
      setDocID(doc.id);
    });
  }, [email, id]);

  async function submitForm(e) {
    console.log(id);
    console.log(email);
    console.log(docid);
    try {
      const docRef = await updateDoc(doc(db, "assessments", docid), {
        status: "Complete",
        endTime: Timestamp.now(),
      });
      var selectedForm = document.getElementById("223490410021439");
      selectedForm.submit();
    } catch (err) {
      alert(err);
    }
  }

  if (days + hours + minutes + seconds <= 0) {
    console.log("expired");
    return <ExpiredNotice />;
  } else {
    return (
      <>
        <ToastContainer />
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />

        <form
          className="jotform-form jotform-form unselectable"
          action="https://submit.jotform.com/submit/223490410021439/"
          method="post"
          name="form_223490410021439"
          id={223490410021439}
          acceptCharset="utf-8"
          autoComplete="on"
        >

          <input type="hidden" name="formID" defaultValue={223490410021439} />
          <input type="hidden" id="JWTContainer" defaultValue="" />
          <input type="hidden" id="cardinalOrderNumber" defaultValue="" />
          <div role="main" className="form-all">
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n      .form-all:before\n      {\n        background: none;\n      }\n    ",
              }}
            />

            <ul className="form-section page-section">
              <li
                id="cid_1"
                className="form-input-wide"
                data-type="control_head"
              >
                <div className="form-header-group  header-large">
                  <div className="header-text httal htvam">
                    <h1
                      id="header_1"
                      className="form-header"
                      data-component="header"
                    >
                      Crypto Fundamentals
                    </h1>
                    <div id="subHeader_1" className="form-subHeader">
                      This is a timed assessment, please remember to submit the
                      form before the timer ends to avoid failing the
                      assessment.
                    </div>
                  </div>
                </div>
              </li>
              <li
                className="form-line always-hidden jf-required"
                data-type="control_email"
                id="id_33"
              >
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_33"
                  htmlFor="input_33"
                >
                  {" "}
                  Candidate Email Address
                  <span className="form-required">*</span>{" "}
                </label>
                <div
                  id="cid_33"
                  className="form-input-wide always-hidden jf-required"
                  data-layout="half"
                >
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <input
                      type="email"
                      id="input_33"
                      name="q33_candidateEmail"
                      className="form-textbox validate[required, Email]"
                      data-defaultvalue=""
                      style={{ width: 310 }}
                      size={310}
                      value={email}
                      data-component="email"
                      aria-labelledby="label_33 sublabel_input_33"
                      required=""
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_33"
                      id="sublabel_input_33"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      example@example.com
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li
                className="form-line always-hidden jf-required"
                data-type="control_email"
                id="id_34"
              >
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_34"
                  htmlFor="input_34"
                >
                  {" "}
                  Employer Email Address<span className="form-required">
                    *
                  </span>{" "}
                </label>
                <div
                  id="cid_34"
                  className="form-input-wide always-hidden jf-required"
                  data-layout="half"
                >
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <input
                      type="email"
                      id="input_34"
                      name="q34_employerEmail"
                      className="form-textbox validate[required, Email]"
                      data-defaultvalue=""
                      style={{ width: 310 }}
                      size={310}
                      value={id}
                      data-component="email"
                      aria-labelledby="label_34 sublabel_input_34"
                      required=""
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_34"
                      id="sublabel_input_34"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      example@example.com
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_4">
                <label
                  className="form-label form-label-top"
                  id="label_4"
                  htmlFor="input_4"
                >
                  {" "}
                  A blockchain is similar in function to:{" "}
                </label>
                <div id="cid_4" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_4"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_0"
                        name="q4_aBlockchain"
                        defaultValue="Server"
                      />
                      <label id="label_input_4_0" htmlFor="input_4_0">
                        Server
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_1"
                        name="q4_aBlockchain"
                        defaultValue="Database"
                      />
                      <label id="label_input_4_1" htmlFor="input_4_1">
                        Database
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_2"
                        name="q4_aBlockchain"
                        defaultValue="API"
                      />
                      <label id="label_input_4_2" htmlFor="input_4_2">
                        API
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_3"
                        name="q4_aBlockchain"
                        defaultValue="List"
                      />
                      <label id="label_input_4_3" htmlFor="input_4_3">
                        List
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_6">
                <label
                  className="form-label form-label-top"
                  id="label_6"
                  htmlFor="input_6"
                >
                  {" "}
                  A block in a blockchain primarily signifies a:{" "}
                </label>
                <div id="cid_6" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_6"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_0"
                        name="q6_aBlock"
                        defaultValue="User"
                      />
                      <label id="label_input_6_0" htmlFor="input_6_0">
                        User
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_1"
                        name="q6_aBlock"
                        defaultValue="Identity"
                      />
                      <label id="label_input_6_1" htmlFor="input_6_1">
                        Identity
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_2"
                        name="q6_aBlock"
                        defaultValue="Coin"
                      />
                      <label id="label_input_6_2" htmlFor="input_6_2">
                        Coin
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_3"
                        name="q6_aBlock"
                        defaultValue="Transaction"
                      />
                      <label id="label_input_6_3" htmlFor="input_6_3">
                        Transaction
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_7">
                <label
                  className="form-label form-label-top"
                  id="label_7"
                  htmlFor="input_7"
                >
                  {" "}
                  What is the difference between a cryptocurrency and a token?{" "}
                </label>
                <div id="cid_7" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_7"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_0"
                        name="q7_whatIs"
                        defaultValue="Cryptocurrency is an asset, while a token is not"
                      />
                      <label id="label_input_7_0" htmlFor="input_7_0">
                        Cryptocurrency is an asset, while a token is not
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_1"
                        name="q7_whatIs"
                        defaultValue="Token has a limited supply"
                      />
                      <label id="label_input_7_1" htmlFor="input_7_1">
                        Token has a limited supply
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_2"
                        name="q7_whatIs"
                        defaultValue="Cryptocurrency is a blockchain-native asset, while the token is a smart contract-based asset"
                      />
                      <label id="label_input_7_2" htmlFor="input_7_2">
                        Cryptocurrency is a blockchain-native asset, while the
                        token is a smart contract-based asset
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_3"
                        name="q7_whatIs"
                        defaultValue="Cryptocurrency is cryptographically secure, while a token does not use cryptography"
                      />
                      <label id="label_input_7_3" htmlFor="input_7_3">
                        Cryptocurrency is cryptographically secure, while a
                        token does not use cryptography
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_8">
                <label
                  className="form-label form-label-top"
                  id="label_8"
                  htmlFor="input_8"
                >
                  {" "}
                  What is the purpose of layers in a blockchain?{" "}
                </label>
                <div id="cid_8" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_8"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_0"
                        name="q8_whatIs8"
                        defaultValue="To scale blockchain applications"
                      />
                      <label id="label_input_8_0" htmlFor="input_8_0">
                        To scale blockchain applications
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_1"
                        name="q8_whatIs8"
                        defaultValue="To easily manage blockchains from a programming viewpoint"
                      />
                      <label id="label_input_8_1" htmlFor="input_8_1">
                        To easily manage blockchains from a programming
                        viewpoint
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_2"
                        name="q8_whatIs8"
                        defaultValue="They provide a solution for increased security of blockchains"
                      />
                      <label id="label_input_8_2" htmlFor="input_8_2">
                        They provide a solution for increased security of
                        blockchains
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_3"
                        name="q8_whatIs8"
                        defaultValue="They are a key factor in keeping blockchains decentralized"
                      />
                      <label id="label_input_8_3" htmlFor="input_8_3">
                        They are a key factor in keeping blockchains
                        decentralized
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_11">
                <label
                  className="form-label form-label-top"
                  id="label_11"
                  htmlFor="input_11"
                >
                  {" "}
                  What is the purpose of layers in a blockchain?{" "}
                </label>
                <div id="cid_11" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_11"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_0"
                        name="q11_whatIs11"
                        defaultValue="It is a system that dictates where the blockchain will run"
                      />
                      <label id="label_input_11_0" htmlFor="input_11_0">
                        It is a system that dictates where the blockchain will
                        run
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_1"
                        name="q11_whatIs11"
                        defaultValue="It is a system for implementing changes to a blockchain"
                      />
                      <label id="label_input_11_1" htmlFor="input_11_1">
                        It is a system for implementing changes to a blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_2"
                        name="q11_whatIs11"
                        defaultValue="It is a system that decides which transactions are valid and which ones are invalid"
                      />
                      <label id="label_input_11_2" htmlFor="input_11_2">
                        It is a system that decides which transactions are valid
                        and which ones are invalid
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_3"
                        name="q11_whatIs11"
                        defaultValue="It is a system designed to secure blockchains"
                      />
                      <label id="label_input_11_3" htmlFor="input_11_3">
                        It is a system designed to secure blockchains
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_checkbox" id="id_10">
                <label
                  className="form-label form-label-top"
                  id="label_10"
                  htmlFor="input_10"
                >
                  {" "}
                  Which of the following are Layer 2 blockchain protocols?{" "}
                </label>
                <div id="cid_10" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_10"
                    data-component="checkbox"
                  >
                    <span className="form-checkbox-item">
                      <span className="dragger-item" />
                      <input
                        type="checkbox"
                        aria-describedby="label_10"
                        className="form-checkbox"
                        id="input_10_0"
                        name="q10_whichOf10[]"
                        defaultValue="Dogecoin"
                      />
                      <label id="label_input_10_0" htmlFor="input_10_0">
                        Dogecoin
                      </label>
                    </span>
                    <span className="form-checkbox-item">
                      <span className="dragger-item" />
                      <input
                        type="checkbox"
                        aria-describedby="label_10"
                        className="form-checkbox"
                        id="input_10_1"
                        name="q10_whichOf10[]"
                        defaultValue="Polygon"
                      />
                      <label id="label_input_10_1" htmlFor="input_10_1">
                        Polygon
                      </label>
                    </span>
                    <span
                      className="form-checkbox-item"
                      style={{ clear: "left" }}
                    >
                      <span className="dragger-item" />
                      <input
                        type="checkbox"
                        aria-describedby="label_10"
                        className="form-checkbox"
                        id="input_10_2"
                        name="q10_whichOf10[]"
                        defaultValue="Chainlink"
                      />
                      <label id="label_input_10_2" htmlFor="input_10_2">
                        Chainlink
                      </label>
                    </span>
                    <span className="form-checkbox-item">
                      <span className="dragger-item" />
                      <input
                        type="checkbox"
                        aria-describedby="label_10"
                        className="form-checkbox"
                        id="input_10_3"
                        name="q10_whichOf10[]"
                        defaultValue="Arbitrum"
                      />
                      <label id="label_input_10_3" htmlFor="input_10_3">
                        Arbitrum
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_12">
                <label
                  className="form-label form-label-top"
                  id="label_12"
                  htmlFor="input_12"
                >
                  {" "}
                  What are consensus mechanisms?{" "}
                </label>
                <div id="cid_12" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_12"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_0"
                        name="q12_whatAre"
                        defaultValue="It is a mechanism designed to agree on changes to a blockchain"
                      />
                      <label id="label_input_12_0" htmlFor="input_12_0">
                        It is a mechanism designed to agree on changes to a
                        blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_1"
                        name="q12_whatAre"
                        defaultValue="It is a system of agreement that determine the validity of transactions on a blockchain"
                      />
                      <label id="label_input_12_1" htmlFor="input_12_1">
                        It is a system of agreement that determine the validity
                        of transactions on a blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_2"
                        name="q12_whatAre"
                        defaultValue="It is a system designed to prove that at least the majority of the blockchain is correct"
                      />
                      <label id="label_input_12_2" htmlFor="input_12_2">
                        It is a system designed to prove that at least the
                        majority of the blockchain is correct
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_3"
                        name="q12_whatAre"
                        defaultValue="It is a mechanism designed to keep a record of all transactions on a blockchain"
                      />
                      <label id="label_input_12_3" htmlFor="input_12_3">
                        It is a mechanism designed to keep a record of all
                        transactions on a blockchain
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_13">
                <label
                  className="form-label form-label-top"
                  id="label_13"
                  htmlFor="input_13"
                >
                  {" "}
                  Why are rewards an important factor in blockchains?{" "}
                </label>
                <div id="cid_13" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_13"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_0"
                        name="q13_whyAre"
                        defaultValue="They are essential to keep the community happy"
                      />
                      <label id="label_input_13_0" htmlFor="input_13_0">
                        They are essential to keep the community happy
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_1"
                        name="q13_whyAre"
                        defaultValue="They are an incentive for developers to secure a blockchain"
                      />
                      <label id="label_input_13_1" htmlFor="input_13_1">
                        They are an incentive for developers to secure a
                        blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_2"
                        name="q13_whyAre"
                        defaultValue="They are an incentive for peers to process transactions"
                      />
                      <label id="label_input_13_2" htmlFor="input_13_2">
                        They are an incentive for peers to process transactions
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_3"
                        name="q13_whyAre"
                        defaultValue="They are an incentive for businesses to adopt a blockchain"
                      />
                      <label id="label_input_13_3" htmlFor="input_13_3">
                        They are an incentive for businesses to adopt a
                        blockchain
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_14">
                <label
                  className="form-label form-label-top"
                  id="label_14"
                  htmlFor="input_14"
                >
                  {" "}
                  What is Web3 and how is it different from the ubiquitous
                  Web2.0 applications?{" "}
                </label>
                <div id="cid_14" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_14"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_0"
                        name="q14_whatIs14"
                        defaultValue="Web3 is just a software or a web application that connects to a blockchain"
                      />
                      <label id="label_input_14_0" htmlFor="input_14_0">
                        Web3 is just a software or a web application that
                        connects to a blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_1"
                        name="q14_whatIs14"
                        defaultValue="Web2 is not secure"
                      />
                      <label id="label_input_14_1" htmlFor="input_14_1">
                        Web2 is not secure
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_2"
                        name="q14_whatIs14"
                        defaultValue="Web3 is just a fancy word for web applications using three layers of encryption"
                      />
                      <label id="label_input_14_2" htmlFor="input_14_2">
                        Web3 is just a fancy word for web applications using
                        three layers of encryption
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_3"
                        name="q14_whatIs14"
                        defaultValue="Web3 is just an application that runs on a blockchain"
                      />
                      <label id="label_input_14_3" htmlFor="input_14_3">
                        Web3 is just an application that runs on a blockchain
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_15">
                <label
                  className="form-label form-label-top"
                  id="label_15"
                  htmlFor="input_15"
                >
                  {" "}
                  What is proof of work?{" "}
                </label>
                <div id="cid_15" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_15"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_0"
                        name="q15_whatIs15"
                        defaultValue="It proves that a certain amount of a specific computational effort has been expended by a peer on a network"
                      />
                      <label id="label_input_15_0" htmlFor="input_15_0">
                        It proves that a certain amount of a specific
                        computational effort has been expended by a peer on a
                        network
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_1"
                        name="q15_whatIs15"
                        defaultValue="It proves that a certain amount of tasks have been undertaken by a peer on a network"
                      />
                      <label id="label_input_15_1" htmlFor="input_15_1">
                        It proves that a certain amount of tasks have been
                        undertaken by a peer on a network
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_2"
                        name="q15_whatIs15"
                        defaultValue="It proves that a certain amount of a specific computational effort will be allocated by a peer on a network"
                      />
                      <label id="label_input_15_2" htmlFor="input_15_2">
                        It proves that a certain amount of a specific
                        computational effort will be allocated by a peer on a
                        network
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_3"
                        name="q15_whatIs15"
                        defaultValue="It proves that a blockchain node is working and fully operational on a network"
                      />
                      <label id="label_input_15_3" htmlFor="input_15_3">
                        It proves that a blockchain node is working and fully
                        operational on a network
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_16">
                <label
                  className="form-label form-label-top"
                  id="label_16"
                  htmlFor="input_16"
                >
                  {" "}
                  What is proof of stake?{" "}
                </label>
                <div id="cid_16" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_16"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_16"
                        className="form-radio"
                        id="input_16_0"
                        name="q16_whatIs16"
                        defaultValue="A governance mechanism that transfers responsibility of verification on validator nodes that have the highest stakes of the associated cryptocurrency."
                      />
                      <label id="label_input_16_0" htmlFor="input_16_0">
                        A governance mechanism that transfers responsibility of
                        verification on validator nodes that have the highest
                        stakes of the associated cryptocurrency.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_16"
                        className="form-radio"
                        id="input_16_1"
                        name="q16_whatIs16"
                        defaultValue="A consensus mechanism that selects validator nodes in a blockchain in proportion to their holdings of the associated cryptocurrency."
                      />
                      <label id="label_input_16_1" htmlFor="input_16_1">
                        A consensus mechanism that selects validator nodes in a
                        blockchain in proportion to their holdings of the
                        associated cryptocurrency.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_16"
                        className="form-radio"
                        id="input_16_2"
                        name="q16_whatIs16"
                        defaultValue="A consensus mechanism that works just like proof of work but is more efficient in its required computational effort."
                      />
                      <label id="label_input_16_2" htmlFor="input_16_2">
                        A consensus mechanism that works just like proof of work
                        but is more efficient in its required computational
                        effort.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_16"
                        className="form-radio"
                        id="input_16_3"
                        name="q16_whatIs16"
                        defaultValue="A consensus mechanism that allows blockchain validator nodes to prove their validity by staking their earnings of the associated cryptocurrency"
                      />
                      <label id="label_input_16_3" htmlFor="input_16_3">
                        A consensus mechanism that allows blockchain validator
                        nodes to prove their validity by staking their earnings
                        of the associated cryptocurrency
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_17">
                <label
                  className="form-label form-label-top"
                  id="label_17"
                  htmlFor="input_17"
                >
                  {" "}
                  Mining is an example of:{" "}
                </label>
                <div id="cid_17" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_17"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_17"
                        className="form-radio"
                        id="input_17_0"
                        name="q17_miningIs"
                        defaultValue="PoS"
                      />
                      <label id="label_input_17_0" htmlFor="input_17_0">
                        PoS
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_17"
                        className="form-radio"
                        id="input_17_1"
                        name="q17_miningIs"
                        defaultValue="PoW"
                      />
                      <label id="label_input_17_1" htmlFor="input_17_1">
                        PoW
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_18">
                <label
                  className="form-label form-label-top"
                  id="label_18"
                  htmlFor="input_18"
                >
                  {" "}
                  The concept of cryptocurrencies was first introduced in:{" "}
                </label>
                <div id="cid_18" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_18"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_18"
                        className="form-radio"
                        id="input_18_0"
                        name="q18_theConcept"
                        defaultValue={1990}
                      />
                      <label id="label_input_18_0" htmlFor="input_18_0">
                        1990
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_18"
                        className="form-radio"
                        id="input_18_1"
                        name="q18_theConcept"
                        defaultValue={2012}
                      />
                      <label id="label_input_18_1" htmlFor="input_18_1">
                        2012
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_18"
                        className="form-radio"
                        id="input_18_2"
                        name="q18_theConcept"
                        defaultValue={2008}
                      />
                      <label id="label_input_18_2" htmlFor="input_18_2">
                        2008
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_18"
                        className="form-radio"
                        id="input_18_3"
                        name="q18_theConcept"
                        defaultValue={1983}
                      />
                      <label id="label_input_18_3" htmlFor="input_18_3">
                        1983
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_19">
                <label
                  className="form-label form-label-top"
                  id="label_19"
                  htmlFor="input_19"
                >
                  {" "}
                  The first paper to introduce the concept of Bitcoin was
                  titled:{" "}
                </label>
                <div id="cid_19" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_19"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_0"
                        name="q19_theFirst"
                        defaultValue="Bitcoin: A Peer-to-Peer Electronic Cash System"
                      />
                      <label id="label_input_19_0" htmlFor="input_19_0">
                        Bitcoin: A Peer-to-Peer Electronic Cash System
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_1"
                        name="q19_theFirst"
                        defaultValue="Bitcoin: Electronic Cash System for the Internet"
                      />
                      <label id="label_input_19_1" htmlFor="input_19_1">
                        Bitcoin: Electronic Cash System for the Internet
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_2"
                        name="q19_theFirst"
                        defaultValue="Bitcoin: A Decentralized Economic System"
                      />
                      <label id="label_input_19_2" htmlFor="input_19_2">
                        Bitcoin: A Decentralized Economic System
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_3"
                        name="q19_theFirst"
                        defaultValue="Bitcoin: A Peer-to-Peer Decentralized Cash System"
                      />
                      <label id="label_input_19_3" htmlFor="input_19_3">
                        Bitcoin: A Peer-to-Peer Decentralized Cash System
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_20">
                <label
                  className="form-label form-label-top"
                  id="label_20"
                  htmlFor="input_20"
                >
                  {" "}
                  Not your keys not your coins means:{" "}
                </label>
                <div id="cid_20" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_20"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_0"
                        name="q20_notYour"
                        defaultValue="If you don't know the password to your cryptocurrency account, you don't have access to your crypto assets"
                      />
                      <label id="label_input_20_0" htmlFor="input_20_0">
                        If you don't know the password to your cryptocurrency
                        account, you don't have access to your crypto assets
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_1"
                        name="q20_notYour"
                        defaultValue="If you don't know the private key to your cryptocurrency wallet, you don't have access to your crypto assets"
                      />
                      <label id="label_input_20_1" htmlFor="input_20_1">
                        If you don't know the private key to your cryptocurrency
                        wallet, you don't have access to your crypto assets
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_2"
                        name="q20_notYour"
                        defaultValue="If you don't know the private key to your cryptocurrency wallet, your crypto assets are under somebody else's control"
                      />
                      <label id="label_input_20_2" htmlFor="input_20_2">
                        If you don't know the private key to your cryptocurrency
                        wallet, your crypto assets are under somebody else's
                        control
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_3"
                        name="q20_notYour"
                        defaultValue="Your private keys and your cryptocurrency assets are always under a centralized entity's control"
                      />
                      <label id="label_input_20_3" htmlFor="input_20_3">
                        Your private keys and your cryptocurrency assets are
                        always under a centralized entity's control
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_21">
                <label
                  className="form-label form-label-top"
                  id="label_21"
                  htmlFor="input_21"
                >
                  {" "}
                  The major difference between Bitcoin and Ethereum is:{" "}
                </label>
                <div id="cid_21" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_21"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_21"
                        className="form-radio"
                        id="input_21_0"
                        name="q21_theMajor"
                        defaultValue="Ethereum can process higher transactions per second"
                      />
                      <label id="label_input_21_0" htmlFor="input_21_0">
                        Ethereum can process higher transactions per second
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_21"
                        className="form-radio"
                        id="input_21_1"
                        name="q21_theMajor"
                        defaultValue="Bitcoins are more valuable than Ethers"
                      />
                      <label id="label_input_21_1" htmlFor="input_21_1">
                        Bitcoins are more valuable than Ethers
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_21"
                        className="form-radio"
                        id="input_21_2"
                        name="q21_theMajor"
                        defaultValue="Bitcoin blockchain has better security"
                      />
                      <label id="label_input_21_2" htmlFor="input_21_2">
                        Bitcoin blockchain has better security
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_21"
                        className="form-radio"
                        id="input_21_3"
                        name="q21_theMajor"
                        defaultValue="Ethereum is a programmable blockchain"
                      />
                      <label id="label_input_21_3" htmlFor="input_21_3">
                        Ethereum is a programmable blockchain
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_22">
                <label
                  className="form-label form-label-top"
                  id="label_22"
                  htmlFor="input_22"
                >
                  {" "}
                  Which of the following is the creator of Bitcoin:{" "}
                </label>
                <div id="cid_22" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_22"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_22"
                        className="form-radio"
                        id="input_22_0"
                        name="q22_whichOf"
                        defaultValue="Bitboy"
                      />
                      <label id="label_input_22_0" htmlFor="input_22_0">
                        Bitboy
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_22"
                        className="form-radio"
                        id="input_22_1"
                        name="q22_whichOf"
                        defaultValue="Satoshi Wright"
                      />
                      <label id="label_input_22_1" htmlFor="input_22_1">
                        Satoshi Wright
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_22"
                        className="form-radio"
                        id="input_22_2"
                        name="q22_whichOf"
                        defaultValue="Craig Nakamoto"
                      />
                      <label id="label_input_22_2" htmlFor="input_22_2">
                        Craig Nakamoto
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_22"
                        className="form-radio"
                        id="input_22_3"
                        name="q22_whichOf"
                        defaultValue="Satoshi Nakamoto"
                      />
                      <label id="label_input_22_3" htmlFor="input_22_3">
                        Satoshi Nakamoto
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_23">
                <label
                  className="form-label form-label-top"
                  id="label_23"
                  htmlFor="input_23"
                >
                  {" "}
                  The current logo of Bitcoin was designed by:{" "}
                </label>
                <div id="cid_23" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_23"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_23"
                        className="form-radio"
                        id="input_23_0"
                        name="q23_theCurrent"
                        defaultValue="The creator of Bitcoin - Satoshi Nakamoto"
                      />
                      <label id="label_input_23_0" htmlFor="input_23_0">
                        The creator of Bitcoin - Satoshi Nakamoto
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_23"
                        className="form-radio"
                        id="input_23_1"
                        name="q23_theCurrent"
                        defaultValue="The creator of Ethereum - Vitalik Buterin"
                      />
                      <label id="label_input_23_1" htmlFor="input_23_1">
                        The creator of Ethereum - Vitalik Buterin
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_23"
                        className="form-radio"
                        id="input_23_2"
                        name="q23_theCurrent"
                        defaultValue="A forum user named Bitboy"
                      />
                      <label id="label_input_23_2" htmlFor="input_23_2">
                        A forum user named Bitboy
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_23"
                        className="form-radio"
                        id="input_23_3"
                        name="q23_theCurrent"
                        defaultValue="A prolific designer"
                      />
                      <label id="label_input_23_3" htmlFor="input_23_3">
                        A prolific designer
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_24">
                <label
                  className="form-label form-label-top"
                  id="label_24"
                  htmlFor="input_24"
                >
                  {" "}
                  What is a primary feature of a cold wallet?{" "}
                </label>
                <div id="cid_24" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_24"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_24"
                        className="form-radio"
                        id="input_24_0"
                        name="q24_whatIs24"
                        defaultValue="It is not connected to the internet"
                      />
                      <label id="label_input_24_0" htmlFor="input_24_0">
                        It is not connected to the internet
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_24"
                        className="form-radio"
                        id="input_24_1"
                        name="q24_whatIs24"
                        defaultValue="It is safer than other options"
                      />
                      <label id="label_input_24_1" htmlFor="input_24_1">
                        It is safer than other options
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_24"
                        className="form-radio"
                        id="input_24_2"
                        name="q24_whatIs24"
                        defaultValue="It can withstand high degrees of temperature"
                      />
                      <label id="label_input_24_2" htmlFor="input_24_2">
                        It can withstand high degrees of temperature
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_24"
                        className="form-radio"
                        id="input_24_3"
                        name="q24_whatIs24"
                        defaultValue="It uses stronger encryption vs hot wallets"
                      />
                      <label id="label_input_24_3" htmlFor="input_24_3">
                        It uses stronger encryption vs hot wallets
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_25">
                <label
                  className="form-label form-label-top"
                  id="label_25"
                  htmlFor="input_25"
                >
                  {" "}
                  Bitcoin’s source code is openly available and easy to copy for
                  nearly any user on the internet?{" "}
                </label>
                <div id="cid_25" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_25"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_25"
                        className="form-radio"
                        id="input_25_0"
                        name="q25_bitcoinsSource"
                        defaultValue="True"
                      />
                      <label id="label_input_25_0" htmlFor="input_25_0">
                        True
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_25"
                        className="form-radio"
                        id="input_25_1"
                        name="q25_bitcoinsSource"
                        defaultValue="False"
                      />
                      <label id="label_input_25_1" htmlFor="input_25_1">
                        False
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_26">
                <label
                  className="form-label form-label-top"
                  id="label_26"
                  htmlFor="input_26"
                >
                  {" "}
                  What is a 51% attack?{" "}
                </label>
                <div id="cid_26" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_26"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_26"
                        className="form-radio"
                        id="input_26_0"
                        name="q26_whatIs26"
                        defaultValue="When 51% of the blockchain is compromised"
                      />
                      <label id="label_input_26_0" htmlFor="input_26_0">
                        When 51% of the blockchain is compromised
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_26"
                        className="form-radio"
                        id="input_26_1"
                        name="q26_whatIs26"
                        defaultValue="When more than half of the blockchain transactions are false"
                      />
                      <label id="label_input_26_1" htmlFor="input_26_1">
                        When more than half of the blockchain transactions are
                        false
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_26"
                        className="form-radio"
                        id="input_26_2"
                        name="q26_whatIs26"
                        defaultValue="When 51% of the nodes in a blockchain are controlled by a single entity or group of entities working together"
                      />
                      <label id="label_input_26_2" htmlFor="input_26_2">
                        When 51% of the nodes in a blockchain are controlled by
                        a single entity or group of entities working together
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_26"
                        className="form-radio"
                        id="input_26_3"
                        name="q26_whatIs26"
                        defaultValue="When 51% of the cryptocurrency is owned by a single entity or group of entities working together"
                      />
                      <label id="label_input_26_3" htmlFor="input_26_3">
                        When 51% of the cryptocurrency is owned by a single
                        entity or group of entities working together
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_27">
                <label
                  className="form-label form-label-top"
                  id="label_27"
                  htmlFor="input_27"
                >
                  {" "}
                  What is supply in a cryptocurrency?{" "}
                </label>
                <div id="cid_27" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_27"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_27"
                        className="form-radio"
                        id="input_27_0"
                        name="q27_whatIs27"
                        defaultValue="It is the number of blockchain nodes in a network"
                      />
                      <label id="label_input_27_0" htmlFor="input_27_0">
                        It is the number of blockchain nodes in a network
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_27"
                        className="form-radio"
                        id="input_27_1"
                        name="q27_whatIs27"
                        defaultValue="It is the total supply of a blockchain's associated cryptocurrency"
                      />
                      <label id="label_input_27_1" htmlFor="input_27_1">
                        It is the total supply of a blockchain's associated
                        cryptocurrency
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_27"
                        className="form-radio"
                        id="input_27_2"
                        name="q27_whatIs27"
                        defaultValue="It is the total supply of new coins minted each day by a blockchain network"
                      />
                      <label id="label_input_27_2" htmlFor="input_27_2">
                        It is the total supply of new coins minted each day by a
                        blockchain network
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_27"
                        className="form-radio"
                        id="input_27_3"
                        name="q27_whatIs27"
                        defaultValue="It is the total number of wallets on a blockchain network"
                      />
                      <label id="label_input_27_3" htmlFor="input_27_3">
                        It is the total number of wallets on a blockchain
                        network
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_28">
                <label
                  className="form-label form-label-top"
                  id="label_28"
                  htmlFor="input_28"
                >
                  {" "}
                  Bitcoin is a ____ asset?{" "}
                </label>
                <div id="cid_28" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_28"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_28"
                        className="form-radio"
                        id="input_28_0"
                        name="q28_bitcoinIs"
                        defaultValue="Inflationary"
                      />
                      <label id="label_input_28_0" htmlFor="input_28_0">
                        Inflationary
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_28"
                        className="form-radio"
                        id="input_28_1"
                        name="q28_bitcoinIs"
                        defaultValue="Deflationary"
                      />
                      <label id="label_input_28_1" htmlFor="input_28_1">
                        Deflationary
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_32">
                <label
                  className="form-label form-label-top"
                  id="label_32"
                  htmlFor="input_32"
                >
                  {" "}
                  TPS in a blockchain stands for:{" "}
                </label>
                <div id="cid_32" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_32"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_32"
                        className="form-radio"
                        id="input_32_0"
                        name="q32_tpsIn"
                        defaultValue="Tokens generated per second"
                      />
                      <label id="label_input_32_0" htmlFor="input_32_0">
                        Tokens generated per second
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_32"
                        className="form-radio"
                        id="input_32_1"
                        name="q32_tpsIn"
                        defaultValue="Transactions per second"
                      />
                      <label id="label_input_32_1" htmlFor="input_32_1">
                        Transactions per second
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_32"
                        className="form-radio"
                        id="input_32_2"
                        name="q32_tpsIn"
                        defaultValue="Transfers per second"
                      />
                      <label id="label_input_32_2" htmlFor="input_32_2">
                        Transfers per second
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_32"
                        className="form-radio"
                        id="input_32_3"
                        name="q32_tpsIn"
                        defaultValue="Total blocks per second"
                      />
                      <label id="label_input_32_3" htmlFor="input_32_3">
                        Total blocks per second
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_29">
                <label
                  className="form-label form-label-top"
                  id="label_29"
                  htmlFor="input_29"
                >
                  {" "}
                  Bitcoin has a hard cap of how many coins?{" "}
                </label>
                <div id="cid_29" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_29"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_29"
                        className="form-radio"
                        id="input_29_0"
                        name="q29_bitcoinHas"
                        defaultValue="21 Million"
                      />
                      <label id="label_input_29_0" htmlFor="input_29_0">
                        21 Million
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_29"
                        className="form-radio"
                        id="input_29_1"
                        name="q29_bitcoinHas"
                        defaultValue="1 Million"
                      />
                      <label id="label_input_29_1" htmlFor="input_29_1">
                        1 Million
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_29"
                        className="form-radio"
                        id="input_29_2"
                        name="q29_bitcoinHas"
                        defaultValue="100 Million"
                      />
                      <label id="label_input_29_2" htmlFor="input_29_2">
                        100 Million
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_29"
                        className="form-radio"
                        id="input_29_3"
                        name="q29_bitcoinHas"
                        defaultValue="1 Billion"
                      />
                      <label id="label_input_29_3" htmlFor="input_29_3">
                        1 Billion
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_30">
                <label
                  className="form-label form-label-top"
                  id="label_30"
                  htmlFor="input_30"
                >
                  {" "}
                  The key ethos of Decentralization is:{" "}
                </label>
                <div id="cid_30" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_30"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_30"
                        className="form-radio"
                        id="input_30_0"
                        name="q30_theKey"
                        defaultValue="Centralization is evil"
                      />
                      <label id="label_input_30_0" htmlFor="input_30_0">
                        Centralization is evil
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_30"
                        className="form-radio"
                        id="input_30_1"
                        name="q30_theKey"
                        defaultValue="Big data monopolies should not exist"
                      />
                      <label id="label_input_30_1" htmlFor="input_30_1">
                        Big data monopolies should not exist
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_30"
                        className="form-radio"
                        id="input_30_2"
                        name="q30_theKey"
                        defaultValue="Dispersing power away from a central authority"
                      />
                      <label id="label_input_30_2" htmlFor="input_30_2">
                        Dispersing power away from a central authority
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_30"
                        className="form-radio"
                        id="input_30_3"
                        name="q30_theKey"
                        defaultValue="The center must not hold"
                      />
                      <label id="label_input_30_3" htmlFor="input_30_3">
                        The center must not hold
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_31">
                <label
                  className="form-label form-label-top"
                  id="label_31"
                  htmlFor="input_31"
                >
                  {" "}
                  Which of the following is a key difference between a Web2 and
                  a Web3 software:{" "}
                </label>
                <div id="cid_31" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_31"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_31"
                        className="form-radio"
                        id="input_31_0"
                        name="q31_whichOf31"
                        defaultValue="Web3 is not written in JavaScript"
                      />
                      <label id="label_input_31_0" htmlFor="input_31_0">
                        Web3 is not written in JavaScript
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_31"
                        className="form-radio"
                        id="input_31_1"
                        name="q31_whichOf31"
                        defaultValue="Web3 requires a user wallet for sign-in"
                      />
                      <label id="label_input_31_1" htmlFor="input_31_1">
                        Web3 requires a user wallet for sign-in
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_31"
                        className="form-radio"
                        id="input_31_2"
                        name="q31_whichOf31"
                        defaultValue="Web3 requires a user to hold some cryptocurrencies"
                      />
                      <label id="label_input_31_2" htmlFor="input_31_2">
                        Web3 requires a user to hold some cryptocurrencies
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_31"
                        className="form-radio"
                        id="input_31_3"
                        name="q31_whichOf31"
                        defaultValue="Web3 has better security on the web pages"
                      />
                      <label id="label_input_31_3" htmlFor="input_31_3">
                        Web3 has better security on the web pages
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_button" id="id_2">
                <div id="cid_2" className="form-input-wide" data-layout="full">
                  <div
                    data-align="auto"
                    className="form-buttons-wrapper form-buttons-auto   jsTest-button-wrapperField"
                  >
                    <button
                      id="input_2"
                      type="button"
                      className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                      data-component="button"
                      data-content=""
                      onClick={submitForm}
                    >
                      Submit Assessment
                    </button>
                  </div>
                </div>
              </li>
              <li style={{ display: "none" }}>
                Should be Empty:{" "}
                <input type="text" name="website" defaultValue="" />
              </li>
            </ul>
          </div>
          <input
            type="hidden"
            className="simple_spc"
            id="simple_spc"
            name="simple_spc"
            defaultValue={223490410021439}
          />
        </form>

        <FooterSmall />
      </>
    );
  }
};
