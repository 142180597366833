import React, { useEffect, useState } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall";
import useWindowDimensions from "./windowDimension";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "components/spinner/spinner";
import "./test.css";
import {
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  Timestamp,
  where,
  query,
  getDocs,
  updateDoc
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import {
  getUserSession,
  removeUserSession,
  setUserSession,
  setSelectedToken,
  getSelectedToken,
} from "../../config/localstorage";
import "../timer/App.css";
import { useCountdown } from "../timer/hooks/useCountdown";
// import CountdownTimer from '../timer/CountdownTimer.js';

import DateTimeDisplay from "../timer/DateTimeDisplay";
// import { useCountdown } from '../timer/hooks/useCountdown';
export default function Test2() {

  const { height, width } = useWindowDimensions();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState();
  const [id, setId] = useState("");
  const [link, setLink] = useState("");
  const [min, setMin] = useState();
  const [block, setBlock] = useState();
  const [docid, setDoc] = useState("");
  const history = useHistory();
  console.log(min)
  const THREE_DAYS_IN_MS = min * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  //leaving popup


  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  // useEffect(() => {
  //   window.history.go(1);
  // }, []);

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   const message =
  //     "Are you sure you want to leave? All provided data will be lost.";
  //   e.returnValue = message;
  //   return message;
  // };



  useEffect(() => {
    const tempData = getSelectedToken();
    console.log(tempData);
    if (tempData) {
      const walletData = JSON.parse(tempData);
      console.log(walletData);
      console.log(walletData.email);
      setEmail(walletData.email);
      console.log(email);
      console.log(typeof email);
      get(walletData.email);
    }
  }, []);
  useEffect(() => {
    status();
  }, [id, docid]);
  async function get(email) {
    console.log("call");
    console.log(email);
    setLoading(true);
    try {
      const docRef = doc(db, "users", email);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        console.log(docSnap.data().employerId);
        console.log(docSnap.data().employerId, "id");
        const id = docSnap.data().employerId;
        const docReff = doc(db, "employers", id);
        const docSnapp = await getDoc(docReff);
        if (docSnapp.exists()) {
          console.log(docSnapp.data());
          console.log(docSnapp.data().email);
          setId(docSnapp.data().email);
          console.log(docSnapp.id, "test ID")
          setDoc(docSnapp.id)
          // setBlock(true);
        }
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function status() {
    console.log("call");
    // const time = Timestamp.now();

    console.log(id, "get");
    const q = query(
      collection(db, "assessments"),
      where("employerEmail", "==", id),
      where("email", "==", email),
      where("test", "==", "Solidity101")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.data(), "data from firebbase");


      if (doc.data().endTime <= time) {
        setBlock(true);
        console.log("time up");
      } else {
        setTime(doc.data().endTime); //end time
        console.log(doc.data().endTime.seconds, "seconds end time");
        const Ftime = doc.data().endTime.seconds;
        console.log(doc.id, "TESTid")
        const current = Timestamp.now();
        console.log(current.seconds, "seconds current time");
        const Ctime = current.seconds;
        const tim = Ftime - Ctime;
        console.log(Math.floor(tim / 60), "minutes");
        const minn = Math.floor(tim / 60);
        setMin(minn + 1)
        setBlock(false);
        setLoading(false);
        console.log("time not up");
      }
    });
  }


  return (
    <>
      <Navbar />

      {!block ? (
        <CountdownTimer
          targetDate={dateTimeAfterThreeDays}
          email={email}
          id={id}

        />
      ) : (
        <>
          {" "}
          <p>You failed to submit your test within the required time</p>
        </>
      )}
    </>
  );
}

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Your time is over!</span>
      <p>You failed to submit your test within the required time</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter" style={{ position: "fixed", top: 0 }}>
      <a
        href="https://tapasadhikary.com"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link"
      >
        <DateTimeDisplay
          value={minutes}
          type={"Mins"}
          isDanger={minutes <= 2}
        />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"Seconds"} />
      </a>
    </div>
  );
};

const CountdownTimer = ({ targetDate, email, id }) => {
  const { height, width } = useWindowDimensions();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [docid, setDocID] = useState("")
  // console.log(email,id , "email in countdown");
  // console.log(docid,"docid")
  if (minutes <= 1 && seconds >= 59) {
    toast.error(
      "You are running out of time! Please submit your assessment before the timer ends.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }

  useEffect(async () => {
    const q = query(
      collection(db, "assessments"),
      where("employerEmail", "==", id),
      where("email", "==", email),
      where("test", "==", "Solidity101")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, "data from firebbase in a component");
      console.log(typeof (doc.id))
      setDocID(doc.id)

    })
  }, [email, id])

  async function submitForm(e) {
    console.log(id);
    console.log(email);
    console.log(docid)
    try {
      const docRef = await updateDoc(doc(db, "assessments", docid), {
        status: "Complete",
        endTime: Timestamp.now()
      });
      var selectedForm = document.getElementById("223500468639459");
      selectedForm.submit();
    } catch (err) {
      alert(err);
    }
  }

  if (days + hours + minutes + seconds <= 0) {
    console.log("expired");
    return <ExpiredNotice />;
  } else {
    return (
      <>
        <ToastContainer />
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
        {/*  */}
        <form
          className="jotform-form jotform-form unselectable"
          action="https://submit.jotform.com/submit/223500468639459/"
          method="post"
          name="form_223500468639459"
          id={223500468639459}
          acceptCharset="utf-8"
          autoComplete="on"
        >
          <input type="hidden" name="formID" defaultValue={223500468639459} />
          <input type="hidden" id="JWTContainer" defaultValue="" />
          <input type="hidden" id="cardinalOrderNumber" defaultValue="" />
          <div role="main" className="form-all">
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n      .form-all:before\n      {\n        background: none;\n      }\n    "
              }}
            />
            <ul className="form-section page-section">
              <li id="cid_1" className="form-input-wide" data-type="control_head">
                <div className="form-header-group  header-large">
                  <div className="header-text httal htvam">
                    <h1 id="header_1" className="form-header" data-component="header">
                      Solidity 101
                    </h1>
                    <div id="subHeader_1" className="form-subHeader">
                      This is a timed assessment, please remember to submit the form
                      before the timer ends to avoid failing the assessment.
                    </div>
                  </div>
                </div>
              </li>
              <li
                className="form-line always-hidden jf-required"
                data-type="control_email"
                id="id_33"
              >
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_33"
                  htmlFor="input_33"
                >
                  {" "}
                  Candidate Email Address<span className="form-required">*</span>{" "}
                </label>
                <div
                  id="cid_33"
                  className="form-input-wide always-hidden jf-required"
                  data-layout="half"
                >
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <input
                      type="email"
                      id="input_33"
                      name="q33_candidateEmail"
                      className="form-textbox validate[required, Email]"
                      data-defaultvalue=""
                      style={{ width: 310 }}
                      size={310}
                      value={email}
                      data-component="email"
                      aria-labelledby="label_33 sublabel_input_33"
                      required=""
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_33"
                      id="sublabel_input_33"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      example@example.com
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li
                className="form-line always-hidden jf-required"
                data-type="control_email"
                id="id_34"
              >
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_34"
                  htmlFor="input_34"
                >
                  {" "}
                  Employer Email Address<span className="form-required">*</span>{" "}
                </label>
                <div
                  id="cid_34"
                  className="form-input-wide always-hidden jf-required"
                  data-layout="half"
                >
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <input
                      type="email"
                      id="input_34"
                      name="q34_employerEmail"
                      className="form-textbox validate[required, Email]"
                      data-defaultvalue=""
                      style={{ width: 310 }}
                      size={310}
                      defaultValue=""
                      value={id}
                      data-component="email"
                      aria-labelledby="label_34 sublabel_input_34"
                      required=""
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_34"
                      id="sublabel_input_34"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      example@example.com
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_4">
                <label
                  className="form-label form-label-top"
                  id="label_4"
                  htmlFor="input_4"
                >
                  {" "}
                  A reentrancy attack on a smart contract occurs when:{" "}
                </label>
                <div id="cid_4" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_4"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_0"
                        name="q4_aReentrancy"
                        defaultValue="An attacking smart contract deletes another smart contract"
                      />
                      <label id="label_input_4_0" htmlFor="input_4_0">
                        An attacking smart contract deletes another smart contract
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_1"
                        name="q4_aReentrancy"
                        defaultValue="An attacking smart contract becomes the owner of another vulnerable smart contract"
                      />
                      <label id="label_input_4_1" htmlFor="input_4_1">
                        An attacking smart contract becomes the owner of another
                        vulnerable smart contract
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_2"
                        name="q4_aReentrancy"
                        defaultValue="An attacking smart contract drains another vulnerable smart contract of its funds"
                      />
                      <label id="label_input_4_2" htmlFor="input_4_2">
                        An attacking smart contract drains another vulnerable smart
                        contract of its funds
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_4"
                        className="form-radio"
                        id="input_4_3"
                        name="q4_aReentrancy"
                        defaultValue="An attacking smart contract keeps sending transactions to another vulnerable smart contract"
                      />
                      <label id="label_input_4_3" htmlFor="input_4_3">
                        An attacking smart contract keeps sending transactions to
                        another vulnerable smart contract
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_6">
                <label
                  className="form-label form-label-top"
                  id="label_6"
                  htmlFor="input_6"
                >
                  {" "}
                  Constantly calling the withdraw function of a vulnerable smart
                  contract before it has time to update its balance makes it vulnerable
                  for which attack:{" "}
                </label>
                <div id="cid_6" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_6"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_0"
                        name="q6_constantlyCalling"
                        defaultValue="DoS attack"
                      />
                      <label id="label_input_6_0" htmlFor="input_6_0">
                        DoS attack
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_1"
                        name="q6_constantlyCalling"
                        defaultValue="Reentrancy attack"
                      />
                      <label id="label_input_6_1" htmlFor="input_6_1">
                        Reentrancy attack
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_2"
                        name="q6_constantlyCalling"
                        defaultValue="Call depth attack"
                      />
                      <label id="label_input_6_2" htmlFor="input_6_2">
                        Call depth attack
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_6"
                        className="form-radio"
                        id="input_6_3"
                        name="q6_constantlyCalling"
                        defaultValue="None of the above"
                      />
                      <label id="label_input_6_3" htmlFor="input_6_3">
                        None of the above
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_7">
                <label
                  className="form-label form-label-top"
                  id="label_7"
                  htmlFor="input_7"
                >
                  {" "}
                  Which of the following is the most famous reentrancy attack:{" "}
                </label>
                <div id="cid_7" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_7"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_0"
                        name="q7_whichOf7"
                        defaultValue="DAO hack"
                      />
                      <label id="label_input_7_0" htmlFor="input_7_0">
                        DAO hack
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_1"
                        name="q7_whichOf7"
                        defaultValue="Modifier hack"
                      />
                      <label id="label_input_7_1" htmlFor="input_7_1">
                        Modifier hack
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_2"
                        name="q7_whichOf7"
                        defaultValue="The Lendf.me Protocol attack"
                      />
                      <label id="label_input_7_2" htmlFor="input_7_2">
                        The Lendf.me Protocol attack
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_3"
                        name="q7_whichOf7"
                        defaultValue="Both a and c"
                      />
                      <label id="label_input_7_3" htmlFor="input_7_3">
                        Both a and c
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_7"
                        className="form-radio"
                        id="input_7_4"
                        name="q7_whichOf7"
                        defaultValue="None of the above"
                      />
                      <label id="label_input_7_4" htmlFor="input_7_4">
                        None of the above
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_8">
                <label
                  className="form-label form-label-top"
                  id="label_8"
                  htmlFor="input_8"
                >
                  {" "}
                  The fee for a transaction is calculated using the formula "Fee = Gas *
                  Gas Price"; Which of the following statements is true:{" "}
                </label>
                <div id="cid_8" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_8"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_0"
                        name="q8_whatIs8"
                        defaultValue="Gas is the cost unit"
                      />
                      <label id="label_input_8_0" htmlFor="input_8_0">
                        Gas is the cost unit
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_1"
                        name="q8_whatIs8"
                        defaultValue="Gas price is a single gas unit's price"
                      />
                      <label id="label_input_8_1" htmlFor="input_8_1">
                        Gas price is a single gas unit's price
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_2"
                        name="q8_whatIs8"
                        defaultValue="Gas price is the cost unit"
                      />
                      <label id="label_input_8_2" htmlFor="input_8_2">
                        Gas price is the cost unit
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_3"
                        name="q8_whatIs8"
                        defaultValue="Gas is the single gas unit’s price"
                      />
                      <label id="label_input_8_3" htmlFor="input_8_3">
                        Gas is the single gas unit’s price
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_4"
                        name="q8_whatIs8"
                        defaultValue="Both a and b"
                      />
                      <label id="label_input_8_4" htmlFor="input_8_4">
                        Both a and b
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_8"
                        className="form-radio"
                        id="input_8_5"
                        name="q8_whatIs8"
                        defaultValue="Both c and d"
                      />
                      <label id="label_input_8_5" htmlFor="input_8_5">
                        Both c and d
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_11">
                <label
                  className="form-label form-label-top"
                  id="label_11"
                  htmlFor="input_11"
                >
                  {" "}
                  The biggest difference between a dapp and a normal app is:{" "}
                </label>
                <div id="cid_11" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_11"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_0"
                        name="q11_whatIs11"
                        defaultValue="A dapp has its backend code running in a decentralized peer-to-peer blockchain network."
                      />
                      <label id="label_input_11_0" htmlFor="input_11_0">
                        A dapp has its backend code running in a decentralized
                        peer-to-peer blockchain network.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_1"
                        name="q11_whatIs11"
                        defaultValue="A dapp has its frontend code running in a decentralized peer-to-peer blockchain network."
                      />
                      <label id="label_input_11_1" htmlFor="input_11_1">
                        A dapp has its frontend code running in a decentralized
                        peer-to-peer blockchain network.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_2"
                        name="q11_whatIs11"
                        defaultValue="A dapp has both frontend and backend code running in a decentralized peer-to-peer blockchain network."
                      />
                      <label id="label_input_11_2" htmlFor="input_11_2">
                        A dapp has both frontend and backend code running in a
                        decentralized peer-to-peer blockchain network.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_3"
                        name="q11_whatIs11"
                        defaultValue="Dapps run in an open-source environment."
                      />
                      <label id="label_input_11_3" htmlFor="input_11_3">
                        Dapps run in an open-source environment.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_4"
                        name="q11_whatIs11"
                        defaultValue="Both a and d"
                      />
                      <label id="label_input_11_4" htmlFor="input_11_4">
                        Both a and d
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_11"
                        className="form-radio"
                        id="input_11_5"
                        name="q11_whatIs11"
                        defaultValue="Both c and d"
                      />
                      <label id="label_input_11_5" htmlFor="input_11_5">
                        Both c and d
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_35">
                <label
                  className="form-label form-label-top"
                  id="label_35"
                  htmlFor="input_35"
                >
                  {" "}
                  Which one is false about dapps:{" "}
                </label>
                <div id="cid_35" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_35"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_35"
                        className="form-radio"
                        id="input_35_0"
                        name="q35_whichOne"
                        defaultValue="Dapp developers need to create their own blockchain"
                      />
                      <label id="label_input_35_0" htmlFor="input_35_0">
                        Dapp developers need to create their own blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_35"
                        className="form-radio"
                        id="input_35_1"
                        name="q35_whichOne"
                        defaultValue="Dapps cannot be controlled by a single authority"
                      />
                      <label id="label_input_35_1" htmlFor="input_35_1">
                        Dapps cannot be controlled by a single authority
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_35"
                        className="form-radio"
                        id="input_35_2"
                        name="q35_whichOne"
                        defaultValue="To interact with a dapp you don’t need to provide your identity"
                      />
                      <label id="label_input_35_2" htmlFor="input_35_2">
                        To interact with a dapp you don’t need to provide your identity
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_35"
                        className="form-radio"
                        id="input_35_3"
                        name="q35_whichOne"
                        defaultValue="All of the above"
                      />
                      <label id="label_input_35_3" htmlFor="input_35_3">
                        All of the above
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_12">
                <label
                  className="form-label form-label-top"
                  id="label_12"
                  htmlFor="input_12"
                >
                  {" "}
                  How many constructors are allowed in a smart contract?{" "}
                </label>
                <div id="cid_12" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_12"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_0"
                        name="q12_howMany"
                        defaultValue="One"
                      />
                      <label id="label_input_12_0" htmlFor="input_12_0">
                        One
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_1"
                        name="q12_howMany"
                        defaultValue="Two"
                      />
                      <label id="label_input_12_1" htmlFor="input_12_1">
                        Two
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_12"
                        className="form-radio"
                        id="input_12_2"
                        name="q12_howMany"
                        defaultValue="Three"
                      />
                      <label id="label_input_12_2" htmlFor="input_12_2">
                        Three
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_13">
                <label
                  className="form-label form-label-top"
                  id="label_13"
                  htmlFor="input_13"
                >
                  {" "}
                  A pragma for a file which will not compile earlier than version 0.6.0
                  and it will also not work on a compiler starting from version 0.7.0
                  will be written as:{" "}
                </label>
                <div id="cid_13" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_13"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_0"
                        name="q13_aPragma"
                        defaultValue="pragma solidity ^0.6.0;"
                      />
                      <label id="label_input_13_0" htmlFor="input_13_0">
                        pragma solidity ^0.6.0;
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_1"
                        name="q13_aPragma"
                        defaultValue="pragma solidity < 0.7.0;"
                      />
                      <label id="label_input_13_1" htmlFor="input_13_1">
                        pragma solidity &lt; 0.7.0;
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_2"
                        name="q13_aPragma"
                        defaultValue="pragma solidity ^0.7.0;"
                      />
                      <label id="label_input_13_2" htmlFor="input_13_2">
                        pragma solidity ^0.7.0;
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_13"
                        className="form-radio"
                        id="input_13_3"
                        name="q13_aPragma"
                        defaultValue="pragma solidity >= 0.6.0;"
                      />
                      <label id="label_input_13_3" htmlFor="input_13_3">
                        pragma solidity &gt;= 0.6.0;
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_14">
                <label
                  className="form-label form-label-top"
                  id="label_14"
                  htmlFor="input_14"
                >
                  {" "}
                  A pragma for a file which will only compile for version 0.8.4 will be
                  written as:{" "}
                </label>
                <div id="cid_14" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_14"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_0"
                        name="q14_whatIs14"
                        defaultValue="pragma solidity 0.8.4;"
                      />
                      <label id="label_input_14_0" htmlFor="input_14_0">
                        pragma solidity 0.8.4;
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_1"
                        name="q14_whatIs14"
                        defaultValue="pragma solidity = 0.8.4;"
                      />
                      <label id="label_input_14_1" htmlFor="input_14_1">
                        pragma solidity = 0.8.4;
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_2"
                        name="q14_whatIs14"
                        defaultValue="pragma solidity ^0.8.4;"
                      />
                      <label id="label_input_14_2" htmlFor="input_14_2">
                        pragma solidity ^0.8.4;
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_14"
                        className="form-radio"
                        id="input_14_3"
                        name="q14_whatIs14"
                        defaultValue="pragma solidity *0.8;"
                      />
                      <label id="label_input_14_3" htmlFor="input_14_3">
                        pragma solidity *0.8;
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_15">
                <label
                  className="form-label form-label-top"
                  id="label_15"
                  htmlFor="input_15"
                >
                  {" "}
                  Which one of the following correctly describes stablecoins:{" "}
                </label>
                <div id="cid_15" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_15"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_0"
                        name="q15_whatIs15"
                        defaultValue="Cryptocurrencies pegged to fiat currency"
                      />
                      <label id="label_input_15_0" htmlFor="input_15_0">
                        Cryptocurrencies pegged to fiat currency
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_1"
                        name="q15_whatIs15"
                        defaultValue="Cryptocurrencies pegged to wrapped tokens"
                      />
                      <label id="label_input_15_1" htmlFor="input_15_1">
                        Cryptocurrencies pegged to wrapped tokens
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_2"
                        name="q15_whatIs15"
                        defaultValue="Fiat currency pegged to wrapped tokens"
                      />
                      <label id="label_input_15_2" htmlFor="input_15_2">
                        Fiat currency pegged to wrapped tokens
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_15"
                        className="form-radio"
                        id="input_15_3"
                        name="q15_whatIs15"
                        defaultValue="None of the above"
                      />
                      <label id="label_input_15_3" htmlFor="input_15_3">
                        None of the above
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_16">
                <label
                  className="form-label form-label-top"
                  id="label_16"
                  htmlFor="input_16"
                >
                  {" "}
                  Wrapped coins are on-chain representation of non-native blockchain
                  assets.{" "}
                </label>
                <div id="cid_16" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_16"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_16"
                        className="form-radio"
                        id="input_16_0"
                        name="q16_whatIs16"
                        defaultValue="True"
                      />
                      <label id="label_input_16_0" htmlFor="input_16_0">
                        True
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_16"
                        className="form-radio"
                        id="input_16_1"
                        name="q16_whatIs16"
                        defaultValue="False"
                      />
                      <label id="label_input_16_1" htmlFor="input_16_1">
                        False
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_17">
                <label
                  className="form-label form-label-top"
                  id="label_17"
                  htmlFor="input_17"
                >
                  {" "}
                  What is the actual role of a wrapped coin?{" "}
                </label>
                <div id="cid_17" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_17"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_17"
                        className="form-radio"
                        id="input_17_0"
                        name="q17_whatIs"
                        defaultValue="To reflect the number of assets backing it"
                      />
                      <label id="label_input_17_0" htmlFor="input_17_0">
                        To reflect the number of assets backing it
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_17"
                        className="form-radio"
                        id="input_17_1"
                        name="q17_whatIs"
                        defaultValue="To reflect the price of the asset backing it"
                      />
                      <label id="label_input_17_1" htmlFor="input_17_1">
                        To reflect the price of the asset backing it
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_17"
                        className="form-radio"
                        id="input_17_2"
                        name="q17_whatIs"
                        defaultValue="To reflect the price of on-chain assets on a non-native blockchain"
                      />
                      <label id="label_input_17_2" htmlFor="input_17_2">
                        To reflect the price of on-chain assets on a non-native
                        blockchain
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_17"
                        className="form-radio"
                        id="input_17_3"
                        name="q17_whatIs"
                        defaultValue="To reflect the price of off-chain assets on a native blockchain"
                      />
                      <label id="label_input_17_3" htmlFor="input_17_3">
                        To reflect the price of off-chain assets on a native blockchain
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_18">
                <label
                  className="form-label form-label-top"
                  id="label_18"
                  htmlFor="input_18"
                >
                  {" "}
                  Wrapped tokens/coins do not help in improving the interoperability
                  between cryptocurrencies.{" "}
                </label>
                <div id="cid_18" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_18"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_18"
                        className="form-radio"
                        id="input_18_0"
                        name="q18_wrappedTokenscoins"
                        defaultValue="True"
                      />
                      <label id="label_input_18_0" htmlFor="input_18_0">
                        True
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_18"
                        className="form-radio"
                        id="input_18_1"
                        name="q18_wrappedTokenscoins"
                        defaultValue="False"
                      />
                      <label id="label_input_18_1" htmlFor="input_18_1">
                        False
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_36">
                <label
                  className="form-label form-label-top"
                  id="label_36"
                  htmlFor="input_36"
                >
                  {" "}
                  Require function consumes all remaining gas when false?{" "}
                </label>
                <div id="cid_36" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_36"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_36"
                        className="form-radio"
                        id="input_36_0"
                        name="q36_requireFunction"
                        defaultValue="True"
                      />
                      <label id="label_input_36_0" htmlFor="input_36_0">
                        True
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_36"
                        className="form-radio"
                        id="input_36_1"
                        name="q36_requireFunction"
                        defaultValue="False"
                      />
                      <label id="label_input_36_1" htmlFor="input_36_1">
                        False
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_20">
                <label
                  className="form-label form-label-top"
                  id="label_20"
                  htmlFor="input_20"
                >
                  {" "}
                  Which of the following is not a cryptographic hash function in
                  solidity: ripemd160, keccak256, ecrecover, sha256{" "}
                </label>
                <div id="cid_20" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_20"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_0"
                        name="q20_whichOf20"
                        defaultValue="ripemd160"
                      />
                      <label id="label_input_20_0" htmlFor="input_20_0">
                        ripemd160
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_1"
                        name="q20_whichOf20"
                        defaultValue="keccak256"
                      />
                      <label id="label_input_20_1" htmlFor="input_20_1">
                        keccak256
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_2"
                        name="q20_whichOf20"
                        defaultValue="ecrecover"
                      />
                      <label id="label_input_20_2" htmlFor="input_20_2">
                        ecrecover
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_3"
                        name="q20_whichOf20"
                        defaultValue="sha256"
                      />
                      <label id="label_input_20_3" htmlFor="input_20_3">
                        sha256
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_4"
                        name="q20_whichOf20"
                        defaultValue="None of the above"
                      />
                      <label id="label_input_20_4" htmlFor="input_20_4">
                        None of the above
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_20"
                        className="form-radio"
                        id="input_20_5"
                        name="q20_whichOf20"
                        defaultValue="All of the above"
                      />
                      <label id="label_input_20_5" htmlFor="input_20_5">
                        All of the above
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_21">
                <label
                  className="form-label form-label-top"
                  id="label_21"
                  htmlFor="input_21"
                >
                  {" "}
                  All wallet addresses represent an account address but all account
                  addresses do not represent a wallet.{" "}
                </label>
                <div id="cid_21" className="form-input-wide" data-layout="full">
                  <div
                    className="form-multiple-column"
                    data-columncount={2}
                    role="group"
                    aria-labelledby="label_21"
                    data-component="radio"
                  >
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_21"
                        className="form-radio"
                        id="input_21_0"
                        name="q21_allWallet"
                        defaultValue="True"
                      />
                      <label id="label_input_21_0" htmlFor="input_21_0">
                        True
                      </label>
                    </span>
                    <span className="form-radio-item">
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_21"
                        className="form-radio"
                        id="input_21_1"
                        name="q21_allWallet"
                        defaultValue="False"
                      />
                      <label id="label_input_21_1" htmlFor="input_21_1">
                        False
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_42">
                <div id="cid_42" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_radio" id="id_19">
                <label
                  className="form-label form-label-top"
                  id="label_19"
                  htmlFor="input_19"
                >
                  {" "}
                  Which statement about the following piece of code is true?{" "}
                </label>
                <div id="cid_19" className="form-input-wide" data-layout="full">
                  <div
                    className="form-single-column"
                    role="group"
                    aria-labelledby="label_19"
                    data-component="radio"
                  >
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_0"
                        name="q19_whichStatement"
                        defaultValue="getMessage is executed before tokenBought is emitted."
                      />
                      <label id="label_input_19_0" htmlFor="input_19_0">
                        getMessage is executed before tokenBought is emitted.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_1"
                        name="q19_whichStatement"
                        defaultValue="tokenBought is not emitted at all."
                      />
                      <label id="label_input_19_1" htmlFor="input_19_1">
                        tokenBought is not emitted at all.
                      </label>
                    </span>
                    <span className="form-radio-item" style={{ clear: "left" }}>
                      <span className="dragger-item" />
                      <input
                        type="radio"
                        aria-describedby="label_19"
                        className="form-radio"
                        id="input_19_2"
                        name="q19_whichStatement"
                        defaultValue="tokenBought is emitted before getMessage is executed."
                      />
                      <label id="label_input_19_2" htmlFor="input_19_2">
                        tokenBought is emitted before getMessage is executed.
                      </label>
                    </span>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_text" id="id_37">
                <div id="cid_37" className="form-input-wide" data-layout="full">
                  <div
                    id="text_37"
                    className="form-html"
                    data-component="text"
                    tabIndex={0}
                  >
                    <p>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        pragma solidity ^0.5.0;
                      </span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        contract HelloWorld {"{"}
                      </span>
                    </p>
                    <p style={{ paddingLeft: 30 }}>
                      <br />
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {" "}
                        event tokenBought();
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {" "}
                        modifier emitGotMessage() {"{"}
                      </span>
                    </p>
                    <p style={{ paddingLeft: 60 }}>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        _;
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {" "}
                        emit tokenBought();
                      </span>
                    </p>
                    <p style={{ paddingLeft: 30 }}>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {"}"}
                      </span>
                    </p>
                    <p style={{ paddingLeft: 30 }}>
                      <br />
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {" "}
                        bytes32 message;
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {" "}
                        constructor (bytes32 myMessage) public {"{"}
                      </span>
                    </p>
                    <p style={{ paddingLeft: 60 }}>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        message = myMessage;
                      </span>
                    </p>
                    <p style={{ paddingLeft: 30 }}>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {"}"}
                      </span>
                    </p>
                    <p style={{ paddingLeft: 30 }}>
                      <br />
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {" "}
                        function getMessage() emitGotMessage public returns(bytes32){" "}
                        {"{"}
                      </span>
                    </p>
                    <p style={{ paddingLeft: 60 }}>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        return message;
                      </span>
                    </p>
                    <p style={{ paddingLeft: 30 }}>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {"}"}
                      </span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontFamily: "terminal, monaco, monospace",
                          color: "#001af5"
                        }}
                      >
                        {"}"}
                      </span>
                    </p>
                  </div>
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_44">
                <div id="cid_44" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_48">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_48"
                  htmlFor="input_48"
                >
                  {" "}
                  What are the two ways different coins maintain a peg to an off-chain
                  asset?{" "}
                </label>
                <div id="cid_48" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_48"
                      className="form-textarea"
                      name="q48_whatAre"
                      style={{ width: 648, height: 100 }}
                      data-component="textarea"
                      aria-labelledby="label_48 sublabel_input_48"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_48"
                      id="sublabel_input_48"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Briefly write the answer in your own words
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_45">
                <div id="cid_45" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_49">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_49"
                  htmlFor="input_49"
                >
                  {" "}
                  How do we make a function able to receive ether?{" "}
                </label>
                <div id="cid_49" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_49"
                      className="form-textarea"
                      name="q49_howDo"
                      style={{ width: 648, height: 100 }}
                      data-component="textarea"
                      aria-labelledby="label_49 sublabel_input_49"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_49"
                      id="sublabel_input_49"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Briefly write the answer in your own words
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_60">
                <div id="cid_60" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_53">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_53"
                  htmlFor="input_53"
                >
                  {" "}
                  Given that we want to create a Movie struct with Title, movie_id and
                  director attributes, how will we declare a struct in solidity?{" "}
                </label>
                <div id="cid_53" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_53"
                      className="form-textarea"
                      name="q53_givenThat"
                      style={{ width: 648, height: 200 }}
                      data-component="textarea"
                      aria-labelledby="label_53 sublabel_input_53"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_53"
                      id="sublabel_input_53"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Write your code in the text box
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_50">
                <div id="cid_50" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_54">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_54"
                  htmlFor="input_54"
                >
                  {" "}
                  Write any function which is able to receive ether from any user.{" "}
                </label>
                <div id="cid_54" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_54"
                      className="form-textarea"
                      name="q54_writeAny"
                      style={{ width: 648, height: 200 }}
                      data-component="textarea"
                      aria-labelledby="label_54 sublabel_input_54"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_54"
                      id="sublabel_input_54"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Write your code in the text box
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_51">
                <div id="cid_51" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_55">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_55"
                  htmlFor="input_55"
                >
                  {" "}
                  Write a function such that the function carries out the addition of
                  two variables supplied as arguments and returns the resultant value.
                  However, this function can only be called by the owner of the
                  contract. Use a modifier to achieve this functionality.{" "}
                </label>
                <div id="cid_55" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_55"
                      className="form-textarea"
                      name="q55_writeA"
                      style={{ width: 648, height: 400 }}
                      data-component="textarea"
                      aria-labelledby="label_55 sublabel_input_55"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_55"
                      id="sublabel_input_55"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Write your code in the text box
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_56">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_56"
                  htmlFor="input_56"
                >
                  {" "}
                  Using the previous code snippet, now add another modifier to the above
                  function which checks that one of the arguments is greater than 0.{" "}
                </label>
                <div id="cid_56" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_56"
                      className="form-textarea"
                      name="q56_usingThe"
                      style={{ width: 648, height: 400 }}
                      data-component="textarea"
                      aria-labelledby="label_56 sublabel_input_56"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_56"
                      id="sublabel_input_56"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Write your code in the text box
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_52">
                <div id="cid_52" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_57">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_57"
                  htmlFor="input_57"
                >
                  {" "}
                  Create a function which checks whether the address supplied as
                  argument is not a zero address.{" "}
                </label>
                <div id="cid_57" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_57"
                      className="form-textarea"
                      name="q57_createA"
                      style={{ width: 648, height: 400 }}
                      data-component="textarea"
                      aria-labelledby="label_57 sublabel_input_57"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_57"
                      id="sublabel_input_57"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Write your code in the text box
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_divider" id="id_59">
                <div id="cid_59" className="form-input-wide" data-layout="full">
                  <div
                    className="divider"
                    aria-label="Divider"
                    data-component="divider"
                    style={{
                      borderBottomWidth: 2,
                      borderBottomStyle: "solid",
                      borderColor: "#d5d5e0",
                      height: 2,
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  />
                </div>
              </li>
              <li className="form-line" data-type="control_textarea" id="id_58">
                <label
                  className="form-label form-label-top form-label-auto"
                  id="label_58"
                  htmlFor="input_58"
                >
                  {" "}
                  Create a modifier such that it prevents a function from being called
                  while it is executing.{" "}
                </label>
                <div id="cid_58" className="form-input-wide" data-layout="full">
                  {" "}
                  <span
                    className="form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      id="input_58"
                      className="form-textarea"
                      name="q58_createA58"
                      style={{ width: 648, height: 400 }}
                      data-component="textarea"
                      aria-labelledby="label_58 sublabel_input_58"
                      defaultValue={""}
                    />{" "}
                    <label
                      className="form-sub-label"
                      htmlFor="input_58"
                      id="sublabel_input_58"
                      style={{ minHeight: 13 }}
                      aria-hidden="false"
                    >
                      Write your code in the text box
                    </label>
                  </span>{" "}
                </div>
              </li>
              <li className="form-line" data-type="control_button" id="id_2">
                <div id="cid_2" className="form-input-wide" data-layout="full">
                  <div
                    data-align="auto"
                    className="form-buttons-wrapper form-buttons-auto   jsTest-button-wrapperField"
                  >
                    <button
                      id="input_2"
                      type="button"
                      className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                      data-component="button"
                      data-content=""
                      onClick={submitForm}
                    >
                      Submit Assessment
                    </button>
                  </div>
                </div>
              </li>
              <li style={{ display: "none" }}>
                Should be Empty: <input type="text" name="website" defaultValue="" />
              </li>
            </ul>
          </div>
          <input
            type="hidden"
            className="simple_spc"
            id="simple_spc"
            name="simple_spc"
            defaultValue={223500468639459}
          />

        </form>
        <FooterSmall />
      </>
    );
  }
};
