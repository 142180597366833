import React, { useEffect, useState } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall";
import useWindowDimensions from "./windowDimension";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "components/spinner/spinner";
import "./test.css";
import {
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  Timestamp,
  where,
  query,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import {
  getUserSession,
  removeUserSession,
  setUserSession,
  setSelectedToken,
  getSelectedToken,
} from "../../config/localstorage";
import "../timer/App.css";
import { useCountdown } from "../timer/hooks/useCountdown";
import DateTimeDisplay from "../timer/DateTimeDisplay";

export default function Test3() {
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [time, setTime] = useState();
  const [id, setId] = useState("");
  const [link, setLink] = useState("");
  const [min, setMin] = useState();
  const [block, setBlock] = useState();
  const [docid, setDoc] = useState("");
  const history = useHistory();
  console.log(min);
  const THREE_DAYS_IN_MS = min * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;




  //leaving popup

  useEffect(() => {

    const tempData = getSelectedToken();
    console.log(tempData);
    if (tempData) {
      const walletData = JSON.parse(tempData);
      console.log(walletData);
      console.log(walletData.email);
      setEmail(walletData.email);
      console.log(email);
      console.log(typeof email);
      get(walletData.email);

    }



  }, [min]);
  useEffect(() => {
    status();
  }, [id, docid]);
  async function get(email) {
    console.log("call");
    console.log(email);
    setLoading(true)
    try {
      const docRef = doc(db, "users", email);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        console.log(docSnap.data().employerId);
        console.log(docSnap.data().employerId, "id");
        const id = docSnap.data().employerId;
        const docReff = doc(db, "employers", id);
        const docSnapp = await getDoc(docReff);
        if (docSnapp.exists()) {
          console.log(docSnapp.data());
          console.log(docSnapp.data().email);
          setId(docSnapp.data().email);
          console.log(docSnapp.id, "test ID");

          setDoc(docSnapp.id);
          // setBlock(true);
        }
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function status() {
    console.log("call");
    // const time = Timestamp.now();

    console.log(id, "get");
    const q = query(
      collection(db, "assessments"),
      where("employerEmail", "==", id),
      where("email", "==", email),
      where("test", "==", "SolidityIntermediate")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.data(), "data from firebbase");

      if (doc.data().endTime <= time) {
        setBlock(true);
        console.log("time up");
      } else {
        setTime(doc.data().endTime); //end time
        console.log(doc.data().endTime.seconds, "seconds end time");
        const Ftime = doc.data().endTime.seconds;
        console.log(doc.id, "TESTid");
        const current = Timestamp.now();
        console.log(current.seconds, "seconds current time");
        const Ctime = current.seconds;
        const tim = Ftime - Ctime;
        console.log(Math.floor(tim / 60), "minutes");
        const minn = Math.floor(tim / 60);
        setMin(minn + 1);
        setBlock(false);
        setLoading(false)
        console.log("time not up");
      }
    });
  }

  return (
    <>
      <Navbar />

      {loading ?
        <LoadingSpinner />
        :
        !block ? (
          <CountdownTimer
            targetDate={dateTimeAfterThreeDays}
            email={email}
            id={id}
          />
        ) : (
          <>
            {" "}
            <p>You failed to submit your test within the required time</p>
          </>
        )}
    </>
  );
}

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Your time is over!</span>
      <p>You failed to submit your test within the required time</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter" style={{ position: "fixed", top: 0 }}>
      <a
        href="https://tapasadhikary.com"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link"
      >
        <DateTimeDisplay
          value={minutes}
          type={"Mins"}
          isDanger={minutes <= 2}
        />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"Seconds"} />
      </a>
    </div>
  );
};

const CountdownTimer = ({ targetDate, email, id }) => {
  const { height, width } = useWindowDimensions();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [docid, setDocID] = useState("");
  // console.log(email,id , "email in countdown");
  // console.log(docid,"docid")
  if (minutes <= 1 && seconds >= 59) {
    toast.error(
      "You are running out of time! Please submit your assessment before the timer ends.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  }

  useEffect(async () => {
    const q = query(
      collection(db, "assessments"),
      where("employerEmail", "==", id),
      where("email", "==", email),
      where("test", "==", "SolidityIntermediate")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, "data from firebbase in a component");
      console.log(typeof doc.id);
      setDocID(doc.id);
    });
  }, [email, id]);

  async function submitForm(e) {
    console.log(id);
    console.log(email);
    console.log(docid);
    try {
      const docRef = await updateDoc(doc(db, "assessments", docid), {
        status: "Complete",
        endTime: Timestamp.now(),
      });
      var selectedForm = document.getElementById("230880805331452");
      selectedForm.submit();
    } catch (err) {
      alert(err);
    }
  }

  if (days + hours + minutes + seconds <= 0) {
    console.log("expired");
    return <ExpiredNotice />;
  } else {
    if (minutes == undefined) {
      return (
        <h1>Loading...</h1>
      )
    } else {
      return (
        <>
          <ToastContainer />
          <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
          {/*  */}
          <form
            className="jotform-form unselectable"
            action="https://submit.jotform.com/submit/230880805331452"
            method="post"
            name="form_230880805331452"
            id={230880805331452}
            acceptCharset="utf-8"
            autoComplete="on"
          >
            <input type="hidden" name="formID" defaultValue={230880805331452} />
            <input type="hidden" id="JWTContainer" defaultValue />
            <input type="hidden" id="cardinalOrderNumber" defaultValue />
            <div role="main" className="form-all">
              <ul className="form-section page-section">
                <li
                  id="cid_1"
                  className="form-input-wide"
                  data-type="control_head"
                >
                  <div className="form-header-group  header-large">
                    <div className="header-text httal htvam">
                      <h1
                        id="header_1"
                        className="form-header"
                        data-component="header"
                      >
                        Solidity Intermediate
                      </h1>
                      <div id="subHeader_1" className="form-subHeader">
                        This is a timed assessment, please remember to submit the
                        form before the timer ends to avoid failing the
                        assessment.
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="form-line always-hidden jf-required"
                  data-type="control_email"
                  id="id_33"
                >
                  <label
                    className="form-label form-label-top form-label-auto"
                    id="label_33"
                    htmlFor="input_33"
                  >
                    {" "}
                    Candidate Email Address
                    <span className="form-required">*</span>{" "}
                  </label>
                  <div
                    id="cid_33"
                    className="form-input-wide always-hidden jf-required"
                    data-layout="half"
                  >
                    {" "}
                    <span
                      className="form-sub-label-container"
                      style={{ verticalAlign: "top" }}
                    >
                      <input
                        type="email"
                        id="input_33"
                        name="q33_candidateEmail"
                        className="form-textbox validate[required, Email]"
                        data-defaultvalue
                        style={{ width: "310px" }}
                        size={310}
                        defaultValue
                        data-component="email"
                        aria-labelledby="label_33 sublabel_input_33"
                        required
                      />
                      <label
                        className="form-sub-label"
                        htmlFor="input_33"
                        id="sublabel_input_33"
                        style={{ minHeight: "13px" }}
                        aria-hidden="false"
                      >
                        example@example.com
                      </label>
                    </span>{" "}
                  </div>
                </li>
                <li
                  className="form-line always-hidden jf-required"
                  data-type="control_email"
                  id="id_34"
                >
                  <label
                    className="form-label form-label-top form-label-auto"
                    id="label_34"
                    htmlFor="input_34"
                  >
                    {" "}
                    Employer Email Address<span className="form-required">
                      *
                    </span>{" "}
                  </label>
                  <div
                    id="cid_34"
                    className="form-input-wide always-hidden jf-required"
                    data-layout="half"
                  >
                    {" "}
                    <span
                      className="form-sub-label-container"
                      style={{ verticalAlign: "top" }}
                    >
                      <input
                        type="email"
                        id="input_34"
                        name="q34_employerEmail"
                        className="form-textbox validate[required, Email]"
                        data-defaultvalue
                        style={{ width: "310px" }}
                        size={310}
                        defaultValue
                        data-component="email"
                        aria-labelledby="label_34 sublabel_input_34"
                        required
                      />
                      <label
                        className="form-sub-label"
                        htmlFor="input_34"
                        id="sublabel_input_34"
                        style={{ minHeight: "13px" }}
                        aria-hidden="false"
                      >
                        example@example.com
                      </label>
                    </span>{" "}
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_4">
                  <label
                    className="form-label form-label-top"
                    id="label_4"
                    htmlFor="input_4"
                  >
                    {" "}
                    What is the difference between a public and a private function
                    in Solidity?{" "}
                  </label>
                  <div id="cid_4" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_4"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_4"
                          className="form-radio"
                          id="input_4_0"
                          name="q4_whatIs4"
                          defaultValue="Public functions can only be called by the contract owner."
                        />
                        <label id="label_input_4_0" htmlFor="input_4_0">
                          Public functions can only be called by the contract
                          owner.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_4"
                          className="form-radio"
                          id="input_4_1"
                          name="q4_whatIs4"
                          defaultValue="Private functions can only be called within the contract."
                        />
                        <label id="label_input_4_1" htmlFor="input_4_1">
                          Private functions can only be called within the
                          contract.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_4"
                          className="form-radio"
                          id="input_4_2"
                          name="q4_whatIs4"
                          defaultValue="Public functions can be called from outside the contract, while private functions can only be called within the contract."
                        />
                        <label id="label_input_4_2" htmlFor="input_4_2">
                          Public functions can be called from outside the
                          contract, while private functions can only be called
                          within the contract.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_4"
                          className="form-radio"
                          id="input_4_3"
                          name="q4_whatIs4"
                          defaultValue="There is no difference"
                        />
                        <label id="label_input_4_3" htmlFor="input_4_3">
                          There is no difference
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_6">
                  <label
                    className="form-label form-label-top"
                    id="label_6"
                    htmlFor="input_6"
                  >
                    {" "}
                    Which of the following is the correct syntax for an event
                    declaration in Solidity?{" "}
                  </label>
                  <div id="cid_6" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_6"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_6"
                          className="form-radio"
                          id="input_6_0"
                          name="q6_whichOf"
                          defaultValue="event EventName(string parameter1, int parameter2);"
                        />
                        <label id="label_input_6_0" htmlFor="input_6_0">
                          event EventName(string parameter1, int parameter2);
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_6"
                          className="form-radio"
                          id="input_6_1"
                          name="q6_whichOf"
                          defaultValue="event EventName(string parameter1; int parameter2);"
                        />
                        <label id="label_input_6_1" htmlFor="input_6_1">
                          event EventName(string parameter1; int parameter2);
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_6"
                          className="form-radio"
                          id="input_6_2"
                          name="q6_whichOf"
                          defaultValue="event EventName(string parameter1, int parameter2)"
                        />
                        <label id="label_input_6_2" htmlFor="input_6_2">
                          event EventName(string parameter1, int parameter2)
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_6"
                          className="form-radio"
                          id="input_6_3"
                          name="q6_whichOf"
                          defaultValue="None of the above"
                        />
                        <label id="label_input_6_3" htmlFor="input_6_3">
                          None of the above
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_7">
                  <label
                    className="form-label form-label-top"
                    id="label_7"
                    htmlFor="input_7"
                  >
                    {" "}
                    Which of the following is true about Solidity's inheritance
                    mechanism?{" "}
                  </label>
                  <div id="cid_7" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_7"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_7"
                          className="form-radio"
                          id="input_7_0"
                          name="q7_whichOf7"
                          defaultValue="A contract can inherit from multiple contracts using multiple inheritance."
                        />
                        <label id="label_input_7_0" htmlFor="input_7_0">
                          A contract can inherit from multiple contracts using
                          multiple inheritance.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_7"
                          className="form-radio"
                          id="input_7_1"
                          name="q7_whichOf7"
                          defaultValue="A contract can inherit from multiple contracts using single inheritance."
                        />
                        <label id="label_input_7_1" htmlFor="input_7_1">
                          A contract can inherit from multiple contracts using
                          single inheritance.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_7"
                          className="form-radio"
                          id="input_7_2"
                          name="q7_whichOf7"
                          defaultValue="A contract can only inherit from one contract."
                        />
                        <label id="label_input_7_2" htmlFor="input_7_2">
                          A contract can only inherit from one contract.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_7"
                          className="form-radio"
                          id="input_7_3"
                          name="q7_whichOf7"
                          defaultValue="Solidity does not support inheritance."
                        />
                        <label id="label_input_7_3" htmlFor="input_7_3">
                          Solidity does not support inheritance.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_8">
                  <label
                    className="form-label form-label-top"
                    id="label_8"
                    htmlFor="input_8"
                  >
                    {" "}
                    Which of the following statements is true about the
                    "msg.sender" keyword in Solidity?{" "}
                  </label>
                  <div id="cid_8" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_8"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_8"
                          className="form-radio"
                          id="input_8_0"
                          name="q8_whatIs8"
                          defaultValue="It refers to the owner of the contract."
                        />
                        <label id="label_input_8_0" htmlFor="input_8_0">
                          It refers to the owner of the contract.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_8"
                          className="form-radio"
                          id="input_8_1"
                          name="q8_whatIs8"
                          defaultValue="It refers to the address of the current function."
                        />
                        <label id="label_input_8_1" htmlFor="input_8_1">
                          It refers to the address of the current function.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_8"
                          className="form-radio"
                          id="input_8_2"
                          name="q8_whatIs8"
                          defaultValue="It refers to the address of the person or contract that called the current function."
                        />
                        <label id="label_input_8_2" htmlFor="input_8_2">
                          It refers to the address of the person or contract that
                          called the current function.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_8"
                          className="form-radio"
                          id="input_8_3"
                          name="q8_whatIs8"
                          defaultValue="It refers to the address of the contract that the current function is a part of."
                        />
                        <label id="label_input_8_3" htmlFor="input_8_3">
                          It refers to the address of the contract that the
                          current function is a part of.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_11">
                  <label
                    className="form-label form-label-top"
                    id="label_11"
                    htmlFor="input_11"
                  >
                    {" "}
                    Which of the following is true about Solidity's "view" and
                    "pure" functions?{" "}
                  </label>
                  <div id="cid_11" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_11"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_11"
                          className="form-radio"
                          id="input_11_0"
                          name="q11_whatIs11"
                          defaultValue='Both "view" and "pure" functions can modify the state of the contract.'
                        />
                        <label id="label_input_11_0" htmlFor="input_11_0">
                          Both "view" and "pure" functions can modify the state of
                          the contract.
                        </label>
                      </span>
                      <li
                        className="form-line always-hidden jf-required"
                        data-type="control_email"
                        id="id_33"
                      >
                        <label
                          className="form-label form-label-top form-label-auto"
                          id="label_33"
                          htmlFor="input_33"
                        >
                          {" "}
                          Candidate Email Address<span className="form-required">*</span>{" "}
                        </label>
                        <div
                          id="cid_33"
                          className="form-input-wide always-hidden jf-required"
                          data-layout="half"
                        >
                          {" "}
                          <span
                            className="form-sub-label-container"
                            style={{ verticalAlign: "top" }}
                          >
                            <input
                              type="email"
                              id="input_33"
                              name="q33_candidateEmail"
                              className="form-textbox validate[required, Email]"
                              data-defaultvalue=""
                              style={{ width: 310 }}
                              size={310}
                              value={email}
                              data-component="email"
                              aria-labelledby="label_33 sublabel_input_33"
                              required=""
                            />{" "}
                            <label
                              className="form-sub-label"
                              htmlFor="input_33"
                              id="sublabel_input_33"
                              style={{ minHeight: 13 }}
                              aria-hidden="false"
                            >
                              example@example.com
                            </label>
                          </span>{" "}
                        </div>
                      </li>
                      <li
                        className="form-line always-hidden jf-required"
                        data-type="control_email"
                        id="id_34"
                      >
                        <label
                          className="form-label form-label-top form-label-auto"
                          id="label_34"
                          htmlFor="input_34"
                        >
                          {" "}
                          Employer Email Address<span className="form-required">*</span>{" "}
                        </label>
                        <div
                          id="cid_34"
                          className="form-input-wide always-hidden jf-required"
                          data-layout="half"
                        >
                          {" "}
                          <span
                            className="form-sub-label-container"
                            style={{ verticalAlign: "top" }}
                          >
                            <input
                              type="email"
                              id="input_34"
                              name="q34_employerEmail"
                              className="form-textbox validate[required, Email]"
                              data-defaultvalue=""
                              style={{ width: 310 }}
                              size={310}
                              defaultValue=""
                              value={id}
                              data-component="email"
                              aria-labelledby="label_34 sublabel_input_34"
                              required=""
                            />{" "}
                            <label
                              className="form-sub-label"
                              htmlFor="input_34"
                              id="sublabel_input_34"
                              style={{ minHeight: 13 }}
                              aria-hidden="false"
                            >
                              example@example.com
                            </label>
                          </span>{" "}
                        </div>
                      </li>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_11"
                          className="form-radio"
                          id="input_11_1"
                          name="q11_whatIs11"
                          defaultValue='"View" functions can modify the state of the contract, while "pure" functions cannot.'
                        />
                        <label id="label_input_11_1" htmlFor="input_11_1">
                          "View" functions can modify the state of the contract,
                          while "pure" functions cannot.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_11"
                          className="form-radio"
                          id="input_11_2"
                          name="q11_whatIs11"
                          defaultValue='"Pure" functions can modify the state of the contract, while "view" functions cannot.'
                        />
                        <label id="label_input_11_2" htmlFor="input_11_2">
                          "Pure" functions can modify the state of the contract,
                          while "view" functions cannot.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_11"
                          className="form-radio"
                          id="input_11_3"
                          name="q11_whatIs11"
                          defaultValue='Neither "view" nor "pure" functions can modify the state of the contract.'
                        />
                        <label id="label_input_11_3" htmlFor="input_11_3">
                          Neither "view" nor "pure" functions can modify the state
                          of the contract.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_35">
                  <label
                    className="form-label form-label-top"
                    id="label_35"
                    htmlFor="input_35"
                  >
                    {" "}
                    What is the function of the emit keyword in Solidity?{" "}
                  </label>
                  <div id="cid_35" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_35"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_35"
                          className="form-radio"
                          id="input_35_0"
                          name="q35_whatIs35"
                          defaultValue="It declares a new variable"
                        />
                        <label id="label_input_35_0" htmlFor="input_35_0">
                          It declares a new variable
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_35"
                          className="form-radio"
                          id="input_35_1"
                          name="q35_whatIs35"
                          defaultValue="It creates a new contract"
                        />
                        <label id="label_input_35_1" htmlFor="input_35_1">
                          It creates a new contract
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_35"
                          className="form-radio"
                          id="input_35_2"
                          name="q35_whatIs35"
                          defaultValue="It triggers an event"
                        />
                        <label id="label_input_35_2" htmlFor="input_35_2">
                          It triggers an event
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_35"
                          className="form-radio"
                          id="input_35_3"
                          name="q35_whatIs35"
                          defaultValue="It returns a value"
                        />
                        <label id="label_input_35_3" htmlFor="input_35_3">
                          It returns a value
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_12">
                  <label
                    className="form-label form-label-top"
                    id="label_12"
                    htmlFor="input_12"
                  >
                    {" "}
                    What does ERC-721 define?{" "}
                  </label>
                  <div id="cid_12" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_12"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_12"
                          className="form-radio"
                          id="input_12_0"
                          name="q12_whatDoes"
                          defaultValue="A standard interface for non-fungible tokens (NFTs)"
                        />
                        <label id="label_input_12_0" htmlFor="input_12_0">
                          A standard interface for non-fungible tokens (NFTs)
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_12"
                          className="form-radio"
                          id="input_12_1"
                          name="q12_whatDoes"
                          defaultValue="A standard interface for fungible tokens (FTs)"
                        />
                        <label id="label_input_12_1" htmlFor="input_12_1">
                          A standard interface for fungible tokens (FTs)
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_12"
                          className="form-radio"
                          id="input_12_2"
                          name="q12_whatDoes"
                          defaultValue="A standard interface for token vesting"
                        />
                        <label id="label_input_12_2" htmlFor="input_12_2">
                          A standard interface for token vesting
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_12"
                          className="form-radio"
                          id="input_12_3"
                          name="q12_whatDoes"
                          defaultValue="A standard interface for token swaps"
                        />
                        <label id="label_input_12_3" htmlFor="input_12_3">
                          A standard interface for token swaps
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_13">
                  <label
                    className="form-label form-label-top"
                    id="label_13"
                    htmlFor="input_13"
                  >
                    {" "}
                    Which function in a Solidity smart contract is executed when a
                    new instance of the contract is created?{" "}
                  </label>
                  <div id="cid_13" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_13"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_13"
                          className="form-radio"
                          id="input_13_0"
                          name="q13_whichFunction"
                          defaultValue="Constructor"
                        />
                        <label id="label_input_13_0" htmlFor="input_13_0">
                          Constructor
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_13"
                          className="form-radio"
                          id="input_13_1"
                          name="q13_whichFunction"
                          defaultValue="Fallback"
                        />
                        <label id="label_input_13_1" htmlFor="input_13_1">
                          Fallback
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_13"
                          className="form-radio"
                          id="input_13_2"
                          name="q13_whichFunction"
                          defaultValue="Receive"
                        />
                        <label id="label_input_13_2" htmlFor="input_13_2">
                          Receive
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_13"
                          className="form-radio"
                          id="input_13_3"
                          name="q13_whichFunction"
                          defaultValue="event"
                        />
                        <label id="label_input_13_3" htmlFor="input_13_3">
                          event
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_61">
                  <label
                    className="form-label form-label-top"
                    id="label_61"
                    htmlFor="input_61"
                  >
                    {" "}
                    Which of the following is true about the fallback function in
                    Solidity?{" "}
                  </label>
                  <div id="cid_61" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_61"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_61"
                          className="form-radio"
                          id="input_61_0"
                          name="q61_whichOf61"
                          defaultValue="It is executed when the contract receives Ether with no data."
                        />
                        <label id="label_input_61_0" htmlFor="input_61_0">
                          It is executed when the contract receives Ether with no
                          data.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_61"
                          className="form-radio"
                          id="input_61_1"
                          name="q61_whichOf61"
                          defaultValue="It is executed when the contract receives data with no Ether."
                        />
                        <label id="label_input_61_1" htmlFor="input_61_1">
                          It is executed when the contract receives data with no
                          Ether.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_61"
                          className="form-radio"
                          id="input_61_2"
                          name="q61_whichOf61"
                          defaultValue="It is executed when the contract is self-destructed."
                        />
                        <label id="label_input_61_2" htmlFor="input_61_2">
                          It is executed when the contract is self-destructed.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_61"
                          className="form-radio"
                          id="input_61_3"
                          name="q61_whichOf61"
                          defaultValue="It is executed when the contract is deployed."
                        />
                        <label id="label_input_61_3" htmlFor="input_61_3">
                          It is executed when the contract is deployed.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_62">
                  <label
                    className="form-label form-label-top"
                    id="label_62"
                    htmlFor="input_62"
                  >
                    {" "}
                    Which of the following is true about gas in Solidity?{" "}
                  </label>
                  <div id="cid_62" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_62"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_62"
                          className="form-radio"
                          id="input_62_0"
                          name="q62_whichOf62"
                          defaultValue="It is used to measure the amount of Ether in a transaction."
                        />
                        <label id="label_input_62_0" htmlFor="input_62_0">
                          It is used to measure the amount of Ether in a
                          transaction.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_62"
                          className="form-radio"
                          id="input_62_1"
                          name="q62_whichOf62"
                          defaultValue="It is a unit of computational effort required to execute a transaction."
                        />
                        <label id="label_input_62_1" htmlFor="input_62_1">
                          It is a unit of computational effort required to execute
                          a transaction.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_62"
                          className="form-radio"
                          id="input_62_2"
                          name="q62_whichOf62"
                          defaultValue="It is used to measure the amount of time it takes to execute a transaction."
                        />
                        <label id="label_input_62_2" htmlFor="input_62_2">
                          It is used to measure the amount of time it takes to
                          execute a transaction.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_62"
                          className="form-radio"
                          id="input_62_3"
                          name="q62_whichOf62"
                          defaultValue="It is a unit of data storage in a contract."
                        />
                        <label id="label_input_62_3" htmlFor="input_62_3">
                          It is a unit of data storage in a contract.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_15">
                  <label
                    className="form-label form-label-top"
                    id="label_15"
                    htmlFor="input_15"
                  >
                    {" "}
                    What is the difference between view and pure functions in
                    Solidity?{" "}
                  </label>
                  <div id="cid_15" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_15"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_15"
                          className="form-radio"
                          id="input_15_0"
                          name="q15_whatIs15"
                          defaultValue="view functions modify state while pure functions do not."
                        />
                        <label id="label_input_15_0" htmlFor="input_15_0">
                          view functions modify state while pure functions do not.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_15"
                          className="form-radio"
                          id="input_15_1"
                          name="q15_whatIs15"
                          defaultValue="pure functions return values while view functions do not."
                        />
                        <label id="label_input_15_1" htmlFor="input_15_1">
                          pure functions return values while view functions do
                          not.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_15"
                          className="form-radio"
                          id="input_15_2"
                          name="q15_whatIs15"
                          defaultValue="view functions read data from state while pure functions do not modify state."
                        />
                        <label id="label_input_15_2" htmlFor="input_15_2">
                          view functions read data from state while pure functions
                          do not modify state.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_15"
                          className="form-radio"
                          id="input_15_3"
                          name="q15_whatIs15"
                          defaultValue="pure functions can modify state while view functions cannot."
                        />
                        <label id="label_input_15_3" htmlFor="input_15_3">
                          pure functions can modify state while view functions
                          cannot.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_17">
                  <label
                    className="form-label form-label-top"
                    id="label_17"
                    htmlFor="input_17"
                  >
                    {" "}
                    Which of the following is true about the msg.sender keyword in
                    Solidity?{" "}
                  </label>
                  <div id="cid_17" className="form-input-wide" data-layout="full">
                    <div
                      className="form-single-column"
                      role="group"
                      aria-labelledby="label_17"
                      data-component="radio"
                    >
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_17"
                          className="form-radio"
                          id="input_17_0"
                          name="q17_whichOf17"
                          defaultValue="It returns the address of the current contract."
                        />
                        <label id="label_input_17_0" htmlFor="input_17_0">
                          It returns the address of the current contract.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_17"
                          className="form-radio"
                          id="input_17_1"
                          name="q17_whichOf17"
                          defaultValue="It returns the address of the calling contract."
                        />
                        <label id="label_input_17_1" htmlFor="input_17_1">
                          It returns the address of the calling contract.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_17"
                          className="form-radio"
                          id="input_17_2"
                          name="q17_whichOf17"
                          defaultValue="It returns the address of the current user."
                        />
                        <label id="label_input_17_2" htmlFor="input_17_2">
                          It returns the address of the current user.
                        </label>
                      </span>
                      <span className="form-radio-item" style={{ clear: "left" }}>
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_17"
                          className="form-radio"
                          id="input_17_3"
                          name="q17_whichOf17"
                          defaultValue="It returns the address of the contract owner."
                        />
                        <label id="label_input_17_3" htmlFor="input_17_3">
                          It returns the address of the contract owner.
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_16">
                  <label
                    className="form-label form-label-top"
                    id="label_16"
                    htmlFor="input_16"
                  >
                    {" "}
                    Solidity is the only programming language that can be used to
                    write smart contracts on the Ethereum Virtual Machines{" "}
                  </label>
                  <div id="cid_16" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_16"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_16"
                          className="form-radio"
                          id="input_16_0"
                          name="q16_whatIs16"
                          defaultValue="True"
                        />
                        <label id="label_input_16_0" htmlFor="input_16_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_16"
                          className="form-radio"
                          id="input_16_1"
                          name="q16_whatIs16"
                          defaultValue="False"
                        />
                        <label id="label_input_16_1" htmlFor="input_16_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_18">
                  <label
                    className="form-label form-label-top"
                    id="label_18"
                    htmlFor="input_18"
                  >
                    {" "}
                    Solidity is a compiled language, meaning that the code needs
                    to be compiled into bytecode before it can be executed on the
                    Ethereum Virtual Machine.{" "}
                  </label>
                  <div id="cid_18" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_18"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_18"
                          className="form-radio"
                          id="input_18_0"
                          name="q18_solidityIs"
                          defaultValue="True"
                        />
                        <label id="label_input_18_0" htmlFor="input_18_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_18"
                          className="form-radio"
                          id="input_18_1"
                          name="q18_solidityIs"
                          defaultValue="False"
                        />
                        <label id="label_input_18_1" htmlFor="input_18_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_36">
                  <label
                    className="form-label form-label-top"
                    id="label_36"
                    htmlFor="input_36"
                  >
                    {" "}
                    The Solidity compiler is a standalone program that can be used
                    to compile Solidity code on any operating system.{" "}
                  </label>
                  <div id="cid_36" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_36"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_36"
                          className="form-radio"
                          id="input_36_0"
                          name="q36_theSolidity"
                          defaultValue="True"
                        />
                        <label id="label_input_36_0" htmlFor="input_36_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_36"
                          className="form-radio"
                          id="input_36_1"
                          name="q36_theSolidity"
                          defaultValue="False"
                        />
                        <label id="label_input_36_1" htmlFor="input_36_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_21">
                  <label
                    className="form-label form-label-top"
                    id="label_21"
                    htmlFor="input_21"
                  >
                    {" "}
                    Solidity contracts can be deployed and executed on any
                    blockchain platform, not just Ethereum{" "}
                  </label>
                  <div id="cid_21" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_21"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_21"
                          className="form-radio"
                          id="input_21_0"
                          name="q21_solidityContracts"
                          defaultValue="True"
                        />
                        <label id="label_input_21_0" htmlFor="input_21_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_21"
                          className="form-radio"
                          id="input_21_1"
                          name="q21_solidityContracts"
                          defaultValue="False"
                        />
                        <label id="label_input_21_1" htmlFor="input_21_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_63">
                  <label
                    className="form-label form-label-top"
                    id="label_63"
                    htmlFor="input_63"
                  >
                    {" "}
                    Solidity code is interpreted by the Ethereum Virtual Machine,
                    which executes the bytecode generated by the Solidity compiler{" "}
                  </label>
                  <div id="cid_63" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_63"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_63"
                          className="form-radio"
                          id="input_63_0"
                          name="q63_solidityCode"
                          defaultValue="True"
                        />
                        <label id="label_input_63_0" htmlFor="input_63_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_63"
                          className="form-radio"
                          id="input_63_1"
                          name="q63_solidityCode"
                          defaultValue="False"
                        />
                        <label id="label_input_63_1" htmlFor="input_63_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_64">
                  <label
                    className="form-label form-label-top"
                    id="label_64"
                    htmlFor="input_64"
                  >
                    {" "}
                    The Solidity compiler generates human-readable bytecode that
                    can be easily understood and debugged{" "}
                  </label>
                  <div id="cid_64" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_64"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_64"
                          className="form-radio"
                          id="input_64_0"
                          name="q64_theSolidity64"
                          defaultValue="True"
                        />
                        <label id="label_input_64_0" htmlFor="input_64_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_64"
                          className="form-radio"
                          id="input_64_1"
                          name="q64_theSolidity64"
                          defaultValue="False"
                        />
                        <label id="label_input_64_1" htmlFor="input_64_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_65">
                  <label
                    className="form-label form-label-top"
                    id="label_65"
                    htmlFor="input_65"
                  >
                    {" "}
                    Solidity is a high-level programming language that is easy to
                    learn and use for both novice and experienced developers{" "}
                  </label>
                  <div id="cid_65" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_65"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_65"
                          className="form-radio"
                          id="input_65_0"
                          name="q65_solidityIs65"
                          defaultValue="True"
                        />
                        <label id="label_input_65_0" htmlFor="input_65_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_65"
                          className="form-radio"
                          id="input_65_1"
                          name="q65_solidityIs65"
                          defaultValue="False"
                        />
                        <label id="label_input_65_1" htmlFor="input_65_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_66">
                  <label
                    className="form-label form-label-top"
                    id="label_66"
                    htmlFor="input_66"
                  >
                    {" "}
                    The Solidity compiler checks for syntax errors and other
                    issues before generating bytecode, which helps catch bugs
                    early in the development process{" "}
                  </label>
                  <div id="cid_66" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_66"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_66"
                          className="form-radio"
                          id="input_66_0"
                          name="q66_theSolidity66"
                          defaultValue="True"
                        />
                        <label id="label_input_66_0" htmlFor="input_66_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_66"
                          className="form-radio"
                          id="input_66_1"
                          name="q66_theSolidity66"
                          defaultValue="False"
                        />
                        <label id="label_input_66_1" htmlFor="input_66_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_radio" id="id_67">
                  <label
                    className="form-label form-label-top"
                    id="label_67"
                    htmlFor="input_67"
                  >
                    {" "}
                    Solidity smart contracts can be updated or modified after they
                    have been deployed on the blockchain.{" "}
                  </label>
                  <div id="cid_67" className="form-input-wide" data-layout="full">
                    <div
                      className="form-multiple-column"
                      data-columncount={2}
                      role="group"
                      aria-labelledby="label_67"
                      data-component="radio"
                    >
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_67"
                          className="form-radio"
                          id="input_67_0"
                          name="q67_soliditySmart"
                          defaultValue="True"
                        />
                        <label id="label_input_67_0" htmlFor="input_67_0">
                          True
                        </label>
                      </span>
                      <span className="form-radio-item">
                        <span className="dragger-item" />
                        <input
                          type="radio"
                          aria-describedby="label_67"
                          className="form-radio"
                          id="input_67_1"
                          name="q67_soliditySmart"
                          defaultValue="False"
                        />
                        <label id="label_input_67_1" htmlFor="input_67_1">
                          False
                        </label>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="form-line" data-type="control_divider" id="id_44">
                  <div id="cid_44" className="form-input-wide" data-layout="full">
                    <div
                      className="divider"
                      data-component="divider"
                      style={{
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderColor: "#d5d5e0",
                        height: "2px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                  </div>
                </li>
                <li className="form-line" data-type="control_textarea" id="id_48">
                  <label
                    className="form-label form-label-top form-label-auto"
                    id="label_48"
                    htmlFor="input_48"
                  >
                    {" "}
                    Explain in detail how you will invalidate a Nonce of a
                    contract that is stuck on the chain with a low gas fee.{" "}
                  </label>
                  <div id="cid_48" className="form-input-wide" data-layout="full">
                    {" "}
                    <span
                      className="form-sub-label-container"
                      style={{ verticalAlign: "top" }}
                    >
                      <textarea
                        id="input_48"
                        className="form-textarea"
                        name="q48_writeA48"
                        style={{ width: "648px", height: "100px" }}
                        data-component="textarea"
                        aria-labelledby="label_48 sublabel_input_48"
                        defaultValue={""}
                      />
                      <label
                        className="form-sub-label"
                        htmlFor="input_48"
                        id="sublabel_input_48"
                        style={{ minHeight: "13px" }}
                        aria-hidden="false"
                      >
                        Briefly write the answer in your own words
                      </label>
                    </span>{" "}
                  </div>
                </li>
                <li className="form-line" data-type="control_divider" id="id_51">
                  <div id="cid_51" className="form-input-wide" data-layout="full">
                    <div
                      className="divider"
                      data-component="divider"
                      style={{
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderColor: "#d5d5e0",
                        height: "2px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                  </div>
                </li>
                <li className="form-line" data-type="control_textarea" id="id_56">
                  <label
                    className="form-label form-label-top form-label-auto"
                    id="label_56"
                    htmlFor="input_56"
                  >
                    {" "}
                    Write a solidity function that prevents any sort of a transfer
                    of an ERC 721 and ERC 1155 contract. This function should make
                    the token non transferable.{" "}
                  </label>
                  <div id="cid_56" className="form-input-wide" data-layout="full">
                    {" "}
                    <span
                      className="form-sub-label-container"
                      style={{ verticalAlign: "top" }}
                    >
                      <textarea
                        id="input_56"
                        className="form-textarea"
                        name="q56_writeA56"
                        style={{ width: "648px", height: "400px" }}
                        data-component="textarea"
                        aria-labelledby="label_56 sublabel_input_56"
                        defaultValue={""}
                      />
                      <label
                        className="form-sub-label"
                        htmlFor="input_56"
                        id="sublabel_input_56"
                        style={{ minHeight: "13px" }}
                        aria-hidden="false"
                      >
                        Write your code in the text box
                      </label>
                    </span>{" "}
                  </div>
                </li>
                <li className="form-line" data-type="control_divider" id="id_52">
                  <div id="cid_52" className="form-input-wide" data-layout="full">
                    <div
                      className="divider"
                      data-component="divider"
                      style={{
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderColor: "#d5d5e0",
                        height: "2px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                  </div>
                </li>
                <li className="form-line" data-type="control_textarea" id="id_57">
                  <label
                    className="form-label form-label-top form-label-auto"
                    id="label_57"
                    htmlFor="input_57"
                  >
                    {" "}
                    Write a Solidity function that calculates the current block
                    reward in Ether, given the block number and the total supply
                    of Ether.{" "}
                  </label>
                  <div id="cid_57" className="form-input-wide" data-layout="full">
                    {" "}
                    <span
                      className="form-sub-label-container"
                      style={{ verticalAlign: "top" }}
                    >
                      <textarea
                        id="input_57"
                        className="form-textarea"
                        name="q57_writeA57"
                        style={{ width: "648px", height: "400px" }}
                        data-component="textarea"
                        aria-labelledby="label_57 sublabel_input_57"
                        defaultValue={""}
                      />
                      <label
                        className="form-sub-label"
                        htmlFor="input_57"
                        id="sublabel_input_57"
                        style={{ minHeight: "13px" }}
                        aria-hidden="false"
                      >
                        Write your code in the text box
                      </label>
                    </span>{" "}
                  </div>
                </li>
                <li className="form-line" data-type="control_divider" id="id_59">
                  <div id="cid_59" className="form-input-wide" data-layout="full">
                    <div
                      className="divider"
                      data-component="divider"
                      style={{
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderColor: "#d5d5e0",
                        height: "2px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    />
                  </div>
                </li>
                <li className="form-line" data-type="control_textarea" id="id_58">
                  <label
                    className="form-label form-label-top form-label-auto"
                    id="label_58"
                    htmlFor="input_58"
                  >
                    {" "}
                    Write a Solidity contract that stores the balance of an
                    address and allows the owner to withdraw funds.{" "}
                  </label>
                  <div id="cid_58" className="form-input-wide" data-layout="full">
                    {" "}
                    <span
                      className="form-sub-label-container"
                      style={{ verticalAlign: "top" }}
                    >
                      <textarea
                        id="input_58"
                        className="form-textarea"
                        name="q58_writeA58"
                        style={{ width: "648px", height: "400px" }}
                        data-component="textarea"
                        aria-labelledby="label_58 sublabel_input_58"
                        defaultValue={""}
                      />
                      <label
                        className="form-sub-label"
                        htmlFor="input_58"
                        id="sublabel_input_58"
                        style={{ minHeight: "13px" }}
                        aria-hidden="false"
                      >
                        Write your code in the text box
                      </label>
                    </span>{" "}
                  </div>
                </li>
                <li className="form-line" data-type="control_button" id="id_2">
                  <div id="cid_2" className="form-input-wide" data-layout="full">
                    <div
                      data-align="auto"
                      className="form-buttons-wrapper form-buttons-auto   jsTest-button-wrapperField"
                    >
                      <button
                        id="input_2"
                        type="submit"
                        className="form-submit-button submit-button jf-form-buttons jsTest-submitField"
                        data-component="button"
                        data-content
                        onClick={submitForm}
                      >
                        Submit Assessment
                      </button>
                    </div>
                  </div>
                </li>
                <li style={{ display: "none" }}>
                  Should be Empty:{" "}
                  <input type="text" name="website" defaultValue />
                </li>
              </ul>
            </div>
            <input
              type="hidden"
              className="simple_spc"
              id="simple_spc"
              name="simple_spc"
              defaultValue={230880805331452}
            />
            <div className="formFooter-heightMask" />

          </form>
          <FooterSmall />
        </>
      );
    }
  }
};