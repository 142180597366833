import React, { useEffect, useState } from "react";
import { getUserSession, removeUserSession, setUserSession, setSelectedToken, getSelectedToken } from '../config/localstorage';
import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../config/firebase"
import FooterSmall from "components/Footers/FooterSmall";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Profile1() {

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")

    useEffect(() => {
        const tempData = getSelectedToken();
        console.log(tempData);
        if (tempData) {
            const walletData = JSON.parse(tempData);
            console.log(walletData);
            console.log(walletData.email);
            setEmail(walletData.email);
            console.log(email)
            console.log(typeof (email))
            get(walletData.email);
        }
    }, [])

    async function get(email) {
        console.log('call')
        console.log(email)

        try {
            const docRef = doc(db, "users", email);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log(docSnap.data())
                console.log(docSnap.data().email);
                console.log(docSnap.data().name);
                setName(docSnap.data().name)
            } else {
                console.log("Document does not exist")
            }

        } catch (error) {
            console.log(error)
        }
        // if (docSnap.exists()) {
        //   console.log("Document data:", docSnap.data());
        // } else {
        //   // doc.data() will be undefined in this case
        //   console.log("No such document!");
        // }
    }


    const win = () => {
        window.location.href = "https://www.gothreeoh.com";
    }


    return (
        <>
            <Navbar />
            <main className="profile-page">
                <section className="relative block h-500-px" >
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"

                    >

                    </div>
                    <div

                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >

                    </div>
                </section>
                <section className="relative py-16" >
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-xl rounded-lg -mt-64" style={{ backgroundColor: "white" }}>
                            <div className="px-6">

                                <div className="mt-0 mb-12">
                                    <h2 className="text-2xl font-bold font-heading">
                                        Assessment Completed!
                                    </h2>
                                    <div className="text-lg leading-normal mt-4 mb-2 text-blueGray-400 font-Onest ">
                                        Thank you for completing the assessment. <br />
                                        Your results will be shared with your employer shortly.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-16 mt-20" >
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-xl rounded-lg -mt-64" style={{ backgroundColor: "white" }}>
                            <div className="px-6">
                                <img
                                    src={require("./company-extended.png").default}
                                    alt="logo"
                                    style={{ width: "200px", height: "10%", marginLeft: "1%", marginTop: "0%" }} />
                                <div className="mt-12 mb-6">
                                    <h2 className="text-2xl font-bold font-heading">
                                        Learn more about Go 3.0!
                                    </h2>
                                    <div className="text-lg leading-normal mt-4 mb-2 text-blueGray-400 font-Onest ">
                                        Level Up Your Web3 Skills with us and discover more about Go 3.0 on Our Community-Driven Talent Platform.
                                    </div>

                                </div>
                                <div className="mt-15">

                                    <button
                                        className="bg-lightBlue-600 text-white active:bg-lightBlue-800 text-sm font-Onest px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-3 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={win}
                                    >
                                        Visit the Website
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <FooterSmall /> */}
            </main>

        </>
    );
}
