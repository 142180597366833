import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getUserSession,
  removeUserSession,
  setUserSession,
  setSelectedToken,
  getSelectedToken,
} from "../../config/localstorage";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth, db } from "../../config/firebase";
import { collection, addDoc, doc, Timestamp, setDoc } from "firebase/firestore";
import "./button.scss";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [idd, setID] = useState(0);
  const [tests, setTest] = useState("");
  let { id, test } = useParams();
  const [block, setBlock] = useState(true);
  // console.log(this.props.match.params.id);

  useEffect(() => {
    if (
      id === undefined ||
      id === null ||
      test === undefined ||
      test === null
    ) {
      console.log(id, test, "undefined");
      setID(0);
      setTest("");
      setBlock(false);
    } else {
      console.log(id, test);
      setID(id);
      setTest(test);
    }

    const tempData = getSelectedToken();
    console.log(tempData);
    if (tempData) {
      const walletData = JSON.parse(tempData);
      console.log(walletData);
      console.log(walletData.email);
      setEmail(walletData.email);
    }
  }, []);

  const { location } = props;

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the "candidateID" query parameter
  const candidateID = queryParams.get("candidateID");

  const provider = new GoogleAuthProvider();
  async function call(email, name) {
    console.log("Cal", idd);
    try {
      await setDoc(doc(db, "users", email), {
        email: email,
        name: name,
        createdTime: Timestamp.now(),
        employerId: idd,
        candidateSupabaseID: candidateID || null,
      });
      if (tests) {
        window.location.href = `/assessments/${tests}`;
      } else {
        toast.error("Please Login through Employer Link", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      alert(err);
    }
  }
  const login = async () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log({ credential, token, user });
        console.log(user.email);

        setSelectedToken({
          email: user.email,
        });
        call(user.email, user.displayName);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log({ errorCode, errorMessage, email, credential });
      });
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full mt-5">
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-5  shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="mb-2">
                  <img
                    src={require("../../assets/img/company-logo.png").default}
                    alt="logo"
                    style={{ width: "10%", paddingTop: "5%", height: "50%", width: "60px", marginLeft: "1.5%" }} />
                  <h1
                    style={{ color: "black", fontSize: "36px", paddingTop: "2%", paddingLeft: "1.5%", fontWeight: "900", lineHeight: "43.2px" }}
                    className="mt-5 text-lg font-heading"
                  >
                    Welcome to Go 3.0 Assessments
                  </h1>
                  <br />
                  <h6
                    className="text-blueGray-500 text-lg font-Onest mt-2"
                    style={{ fontFamily: "Onest", fontSize: "16", paddingLeft: "1.5%"}}
                  >
                    Sign in with Google to view your assessments
                  </h6>
                </div>
                <div className="btn-wrapper">
                  {block ?
                    <button onClick={login} class="ml-3 mt-5">
                      <div class="google-btn">
                        <div class="google-icon-wrapper">
                          <img
                            class="google-icon"
                            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                          />
                        </div>
                        <p class="btn-text">
                          <b>Sign in with google</b>
                        </p>
                      </div>
                    </button>
                    :
                    <h6 style={{ fontFamily: "Onest", fontSize: "16", paddingLeft:"3%" }}>Please use the assessment link provided to you by your employer to login to our platform.
                    </h6>}
                </div>
              </div>
              <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex flex-wrap mt-6 relative">
              {/* <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
