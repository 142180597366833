import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore"
const firebaseConfig = {
apiKey: "AIzaSyBGjr6neP8c74cAxraUJrDdv0RgKum1Oo8",
  authDomain: "gothreeoh-tests.firebaseapp.com",
  projectId: "gothreeoh-tests",
  storageBucket: "gothreeoh-tests.appspot.com",
  messagingSenderId: "1063508670338",
  appId: "1:1063508670338:web:9780b78f196696bad7c19b",
  measurementId: "G-W7RVC7YTEE"
};

if (!getApps().length) {
    initializeApp(firebaseConfig);
}

export const auth = getAuth();
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export {db}
