import React from "react";

export default function FooterSmall(props) {
  return (
    <>
      <footer
   
        className={
          (props.absolute
            ? "absolute w-full bottom-0 "
            : "relative") + " pb-6"
        }
      >
        <div className="container mx-auto px-4">
       
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-12/12 px-4">
              <div className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-center">
              Building the future of our web with 💛
               
              </div>
            </div>
            <div className="w-full md:w-8/12 px-4">
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
